<template>
  <div class="line-item">
    <div class="line-item__thumbnail">
      <shopify-image :image="item.variant.image" />
    </div>
    <div class="line-item__info">
      <b-row class="mb-4">
        <b-col>
          <span class="line-item__product-title">
            {{ item.title }}
          </span>
          <span class="line-item__product-price">
            <span class="compare-at-price" v-if="item.variant.compareAtPrice">
              {{ formatPrice(item.variant.compareAtPrice) }}
            </span>
            <span class="price" :class="{ red: item.variant.compareAtPrice }">
              {{ price }}
            </span>
          </span>

          <div class="line-item__product-option__color" v-if="color.color">
            <span
              class="color-swatch"
              :style="{ background: color.color }"
            ></span>
            <span class="label">
              {{ color.name }}
            </span>
          </div>
          <div class="line-item__product-option__size" v-if="color.color">
            <span class="label">
              {{ size }}
            </span>
          </div>
          <div
            class="line-item__product-option__mobile"
            :class="{ desktop: !color.color }"
          >
            <span class="label">
              {{ variantTextMobile }}
            </span>
          </div>
        </b-col>
      </b-row>
      <b-row align-v="center" align-h="end">
        <b-col cols="6">
          <b-form-spinbutton
            inline
            size="sm"
            :value="item.quantity"
            min="1"
            max="100"
            :disabled="processing"
            @input="onUpdateQuantity"
          >
            <template v-slot:decrement>–</template>
            <template v-slot:increment>+</template>
          </b-form-spinbutton>
        </b-col>
        <b-col cols="6">
          <span class="line-item__remove" @click="remove">
            <div class="txt">{{ $t('shop.remove') }}</div>
            <TrashCanIcon width="20px" />
          </span>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import TrashCanIcon from '~/assets/svg/trash-can-icon.svg?inline'
export default {
  components: {
    TrashCanIcon
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      processing: false,
      color: {},
      size: {},
      typeMatches: {
        color: ['color', 'farbe'],
        size: ['size', 'größe']
      }
    }
  },
  computed: {
    price() {
      return this.formatPrice(this.item.variant.price.amount)
    },
    variantTextMobile() {
      let label = ''
      for (let i = 0; i < this.item.variant.selectedOptions.length; i++) {
        label =
          i > 0
            ? `${label}, ${this.item.variant.selectedOptions[
                i
              ].value.toLowerCase()}`
            : this.item.variant.selectedOptions[i].value.toLowerCase()
      }
      return label
    }
  },
  created() {
    this.item.variant.selectedOptions.forEach((option) => {
      if (
        this.typeMatches.color.find(
          (type) => type === option.name.toLowerCase()
        )
      ) {
        this.color = this.getColor(option.value)
          ? this.getColor(option.value)
          : ''
      } else if (
        this.typeMatches.size.find((type) => type === option.name.toLowerCase())
      ) {
        this.size = option.value
      }
    })
  },
  methods: {
    async remove() {
      const lineItemIdsToRemove = [this.item.id]
      await this.$store.dispatch('shop/removeLineItems', lineItemIdsToRemove)
    },
    async onUpdateQuantity(quantity) {
      this.processing = true
      this.$emit('processing', true)
      const lineItemsToUpdate = [{ id: this.item.id, quantity }]
      await this.$store.dispatch('shop/updateLineItems', lineItemsToUpdate)
      this.processing = false
      this.$emit('processing', false)
    },
    formatPrice(num) {
      return new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR'
      }).format(num)
    },
    getColor(name) {
      if (name.includes('/')) {
        const splitColors = this._.compact(name.split('/'))
        let gradient = 'linear-gradient(90deg'
        let foundAll = true
        splitColors.forEach((subColor, index) => {
          const cCol = this.getColor(subColor)
          if (!cCol) {
            foundAll = false
            return false
          } else {
            gradient += `, ${cCol.color} ${100 / splitColors.length}%`
          }
        })
        if (!foundAll) {
          return false
        }
        gradient += ')'
        return {
          name,
          color: gradient
        }
      }

      return this._.chain(this.$store.state.site.options.filter.colors)
        .map('matches')
        .flatten()
        .filter({ name })
        .first()
        .value()
    }
  }
}
</script>

<style lang="scss">
.line-item {
  position: relative;
  width: 100%;
  padding: 1rem 0;
  display: flex;
  align-items: stretch;

  &__thumbnail {
    flex: 0 0 100px;

    @include media-breakpoint-up(sm) {
      margin-right: 1.5rem;
    }
    @include media-breakpoint-up(md) {
      flex: 0 0 152px;
    }
    img {
      width: 100%;
      height: auto;
    }
  }

  &__info {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__product-title {
    display: block;
  }

  &__product-price {
    display: block;
    margin-bottom: 1.25rem;

    .compare-at-price {
      display: inline-block;
      margin-right: 8px;
      position: relative;

      &:after {
        content: '';
        width: 100%;
        height: 0.1rem;
        position: absolute;
        top: 40%;
        left: 0;
        transform: translateY(-50%);
        background: #ff0000;
      }
    }
    .price {
      &.red {
        color: #ff0000;
      }
    }
  }

  &__product-option {
    display: block;

    &__color {
      display: none;
      @include media-breakpoint-up(md) {
        display: block;
      }
      .label {
        color: #979797;
        font-weight: 400;
        position: relative;
        top: -1px;
      }

      .color-swatch {
        border: 1px solid #ccc;
        border-radius: 50%;
        display: inline-block;
        margin-right: 8px;
        transform: translateY(-1px);
        height: 20px;
        width: 20px;
        vertical-align: text-bottom;
      }
    }
    &__size {
      margin-top: 0.75rem;
      font-weight: 400;
      display: none;
      @include media-breakpoint-up(md) {
        display: block;
      }
    }
    &__mobile {
      display: block;
      text-transform: capitalize;
      @include media-breakpoint-up(md) {
        display: none;
      }

      &.desktop {
        display: block !important;
      }
    }
  }

  &__remove {
    cursor: pointer;
    text-decoration: underline;
    transition: 0.1s opacity ease-in-out;
    text-align: right;
    &:hover {
      opacity: 0.7;
    }
    .txt {
      display: none;

      @include media-breakpoint-up(md) {
        display: block;
      }
    }
    svg {
      display: block;
      float: right;

      @include media-breakpoint-up(md) {
        display: none;
      }
    }
  }

  .b-form-spinbutton {
    border: none;
    button {
      min-width: unset;
      padding: 0;
      color: $text-color;

      &:hover {
        color: #1c0f0c;
      }
    }
    output {
      border: none !important;
      padding: 0;

      bdi {
        position: relative;
        top: 3px;
      }
    }
    &:focus,
    &.focus {
      box-shadow: none !important;
    }
    &.disabled {
      color: #ccc;
      background: none;
      button {
        color: #ccc;
      }
    }
  }
}
</style>
