import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _16ec6274 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _04b2d1b8 = () => interopDefault(import('../pages/stories/index.vue' /* webpackChunkName: "pages/stories/index" */))
const _b140ebe8 = () => interopDefault(import('../pages/product/_slug.vue' /* webpackChunkName: "pages/product/_slug" */))
const _02fc3070 = () => interopDefault(import('../pages/stories/_slug.vue' /* webpackChunkName: "pages/stories/_slug" */))
const _97537efe = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'active',
  linkExactActiveClass: 'exact-active',
  scrollBehavior,

  routes: [{
    path: "/en/",
    component: _16ec6274,
    pathToRegexpOptions: {"strict":true},
    name: "index___en"
  }, {
    path: "/stories/",
    component: _04b2d1b8,
    pathToRegexpOptions: {"strict":true},
    name: "stories___de"
  }, {
    path: "/en/stories/",
    component: _04b2d1b8,
    pathToRegexpOptions: {"strict":true},
    name: "stories___en"
  }, {
    path: "/en/product/:slug?/",
    component: _b140ebe8,
    pathToRegexpOptions: {"strict":true},
    name: "product-slug___en"
  }, {
    path: "/en/stories/:slug/",
    component: _02fc3070,
    pathToRegexpOptions: {"strict":true},
    name: "stories-slug___en"
  }, {
    path: "/product/:slug?/",
    component: _b140ebe8,
    pathToRegexpOptions: {"strict":true},
    name: "product-slug___de"
  }, {
    path: "/stories/:slug/",
    component: _02fc3070,
    pathToRegexpOptions: {"strict":true},
    name: "stories-slug___de"
  }, {
    path: "/en/*/",
    component: _97537efe,
    pathToRegexpOptions: {"strict":true},
    name: "all___en"
  }, {
    path: "/",
    component: _16ec6274,
    pathToRegexpOptions: {"strict":true},
    name: "index___de"
  }, {
    path: "/*/",
    component: _97537efe,
    pathToRegexpOptions: {"strict":true},
    name: "all___de"
  }],

  fallback: false
}

export function createRouter () {
  const router = new Router(routerOptions)
  const resolve = router.resolve.bind(router)

  // encodeURI(decodeURI()) ~> support both encoded and non-encoded urls
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = encodeURI(decodeURI(to))
    }
    return resolve(to, current, append)
  }

  return router
}
