<template>
  <component :is="tag" v-bind="$attrs" v-on="$listeners" class="base-section">
    <slot></slot>
  </component>
</template>
<script>
export default {
  name: 'BaseSection',
  props: {
    tag: {
      type: String,
      default: () => 'section'
    }
  }
}
</script>
<style lang="scss">
.base-section {
  background-color: white;
  clip: rect(auto, auto, auto, auto);
}
</style>
