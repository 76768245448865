var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// @ts-ignore
import ShopifyModule from 'shopify-buy';
import 'isomorphic-unfetch';
export default (ctx, inject) => __awaiter(void 0, void 0, void 0, function* () {
    const config = {
        domain: 'lille-compagnie.myshopify.com',
        storefrontAccessToken: '28582efa0e59cd74ec9e102057f91681',
        language: '',
    };
    const client = ShopifyModule.buildClient(config);
    Object.assign(client, {
        buildClient: (options, fetchClient) => {
            const newClient = ShopifyModule.buildClient(options, fetchClient);
            ctx.$shopify = newClient;
            ctx.app.$shopify = newClient;
            inject('shopify', newClient);
        },
    });
    // Inject shopify to the context as $shopify
    ctx.$shopify = client;
    inject('shopify', client);
});
