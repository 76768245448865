import { gsap, Power4 } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

const findEl = (hash, x) => {
  return (
    document.querySelector(hash) ||
    new Promise((resolve, reject) => {
      if (x > 20) {
        return resolve()
      }
      setTimeout(() => {
        resolve(findEl(hash, ++x || 1))
      }, 100)
    })
  )
}

const sleep = (milliseconds) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
}

if (process.client) {
  gsap.registerPlugin(ScrollToPlugin)
  gsap.registerPlugin(ScrollTrigger)
}

export default async (to, from, savedPosition) => {
  const app = window.$nuxt
  let pageReadyResolve = false
  let positionReady = false

  // console.log('check scroll pos')

  let position = {
    x: 0,
    y: 0
  }

  const prom = new Promise(resolve => {
    app.$root.$once('scrollOnMount', () => {
      // console.log('page ready')
      if (positionReady) {
        // console.log('resolve', position)
        setTimeout(() => (
          resolve(position)
        ), 1)
      } else {
        // console.log('wait for position')
        pageReadyResolve = resolve
      }
    })
  })

  if (to.hash && to.hash.indexOf('#variant-') !== 0) {
    // console.log('try to find hash el')
    const el = await findEl(to.hash)
    if (el) {
      // console.log('update position', to.hash, el.offsetTop)
      position.y = el.offsetTop

      if (to.hash && to.path === from.path && to.fullPath !== from.fullPath) {
        await sleep(1)
        gsap.to(window, 0.7, {
          scrollTo: { y: position.y, autoKill: false },
          ease: Power4.easeOut
        })
        return false
      }
    }
  } else if (savedPosition) {
    // console.log('update position to saved', savedPosition.y)
    position.x = savedPosition.x
    position.y = savedPosition.y
  }

  // console.log('position ready')

  if (pageReadyResolve) {
    // console.log('resolve', position)
    pageReadyResolve(position)
  } else {
    // console.log('wait for page')
    positionReady = true
  }
  return prom
}
