var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{directives:[{name:"show",rawName:"v-show",value:(_vm.showHeader),expression:"showHeader"}],staticClass:"main-header",class:{
    fixed: _vm.fixed,
    'shift-left': this.$store.state.ui.shiftLeft && !_vm.isInContent,
    'shift-right': this.$store.state.ui.shiftRight && !_vm.isInContent,
    hasBorder: _vm.scrolledEnough
  }},[_c('b-container',{staticClass:"main-header__wrapper"},[_c('div',{staticClass:"main-header__burger",class:{ collapsed: this.$store.state.ui.navOpen },on:{"click":function($event){return _vm.$store.commit('ui/toggleNav')}}},[_c('div',{staticClass:"line"})]),_vm._v(" "),_c('transition',[_c('nuxt-link',{directives:[{name:"show",rawName:"v-show",value:(!_vm.logoHidden),expression:"!logoHidden"}],staticClass:"lille-logo",attrs:{"to":_vm.localePath('/')}},[_c('Typogram')],1)],1),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[_c('div',{staticClass:"main-header__shopping-cart "},[_c('div',{staticClass:"desktop lt",class:{
            hide: this.$store.state.ui.shiftRight
          },on:{"click":function($event){return _vm.$store.commit('ui/openCart')}}},[_vm._v("\n          "+_vm._s(_vm.$t('shop.cart'))+"\n          ("+_vm._s(_vm.$store.state.shop.checkout.lineItems.length)+")\n        ")]),_vm._v(" "),_c('div',{staticClass:"mobile",on:{"click":function($event){return _vm.$store.commit('ui/openCart')}}},[_c('ShoppingBagIcon',{attrs:{"width":"18px"}}),_vm._v(" "),_c('div',{staticClass:"bubble md"},[_vm._v("\n            "+_vm._s(_vm.$store.state.shop.checkout.lineItems.length)+"\n          ")])],1)])])],1),_vm._v(" "),_c('shift-backdrop')],1)}
var staticRenderFns = []

export { render, staticRenderFns }