<template>
  <div
    v-if="isVisible"
    class="news-banner-wrapper"
    :style="{ backgroundColor: fields.background_color }"
  >
    <div class="news-banner" ref="newsbanner">
      <base-link
        class="news-banner-link"
        :class="fields.copy_color"
        :linkdata="fields.link"
      >
        <p v-html="fields.copy"></p>
      </base-link>
      <div class="closer" @click.prevent="closeNews">
        <span :class="fields.copy_color"></span>
        <span :class="fields.copy_color"></span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isVisible: true,
      scrollPos: 0,
      scrolledEnough: false,
      isHidden: false,
      dom: false,
      in: false
    }
  },
  computed: {
    fields() {
      return this.$store.state.site.options.news_alert
    }
  },
  beforeMount() {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  mounted() {
    this.dom = document.getElementsByClassName('app')[0]
  },
  methods: {
    closeNews() {
      //       const app = document.getElementsByClassName('app')[0]
      this.dom.classList.remove('activeOffset')
      setTimeout(() => {
        this.isVisible = false
        this.isHidden = true
      }, 400)
    },
    handleScroll() {
      this.scrollPos = window.scrollY
      if (!this.isHidden) {
        if (this.scrollPos >= 150) {
          if (!this.in) {
            this.in = true
            this.dom.classList.remove('activeOffset')
          }
        } else if (this.in) {
          this.dom.classList.add('activeOffset')
          this.in = false
        }
      }
    }
  }
}
</script>

<style lang="scss">
.news-banner-wrapper {
  will-change: top;
  z-index: 90;
  display: flex;
  position: fixed;
  width: 100%;
  height: auto;
  min-height: 50px;
  top: -70px;
  background-color: #ffdd8e;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: top $shift-animation-easing $shift-animation-duration;
  @include media-breakpoint-up(md) {
    top: -50px;
  }
  .activeOffset & {
    top: 0;
  }
  &:after {
    content: ' ';
    z-index: 91;
    position: absolute;
    display: block;
    height: 1px;
    width: 100%;
    bottom: -1px;
    background-color: transparent;
    box-shadow: 0px -1px 6px 1px rgba(0, 0, 0, 0.43);
  }

  .news-banner {
    width: calc(100vw - 90px);
    max-width: 100%;

    @include media-breakpoint-up(md) {
      width: calc(100vw - 100px);
    }

    .news-banner-link {
      &.white {
        color: $white;
        p {
          color: $white;
        }
      }
      p {
        text-align: center;
        margin: 0 0;
        padding: 12px 0;
        @include media-breakpoint-up(md) {
          transform: translateY(2px);
          padding: 0 0;
        }
      }
    }

    .closer {
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
      width: 30px;
      height: 30px;
      background-color: rgba(255, 0, 255, 0);

      cursor: pointer;
      @include media-breakpoint-up(md) {
        right: 43px;
      }
      &:hover {
        span {
          background-color: rgba($black, 0.5);
          &.white {
            background-color: rgba($white, 0.5);
          }
        }
      }

      span {
        position: absolute;
        display: inline-block;
        width: 16px;
        height: 2px;
        background-color: $black;
        transform-origin: center center;
        transition: background-color ease 0.4s;
        top: 50%;
        left: 50%;
        &.white {
          background-color: $white;
        }
        &:first-child {
          transform: translate(-50%, -50%) rotate(45deg);
        }
        &:last-child {
          transform: translate(-50%, -50%) rotate(-45deg);
        }
      }
    }
  }
}
</style>
