<template>
  <div class="filter-nav-urls">
    <ul class="urls-list">
      <li v-for="(item, i) in data" :key="i">
        <template v-if="!item.spacer">
          <nuxt-link
            :to="{ path: item.link, hash: 'products' }"
            class="url-item-btn"
          >
            {{ item.title }}
          </nuxt-link>
        </template>
        <br v-else />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss">
.filter-nav-urls {
  ul {
    margin: 0 0;
    padding: 0 0;
    list-style: none;
    li {
      margin: 0 0;
      padding: 0 0;
      .url-item-btn {
        display: inline-block;
        // font-family: $font-family-serif;
        letter-spacing: 0.5px;
        outline: none;
        border: none;
        background: transparent;
        padding: 8px 0;
        color: #979797;
        transition: color ease 0.4s;
        text-decoration: none;
        &:hover,
        &.active {
          color: $black;
          font-weight: normal;
        }
      }
    }
  }
}
</style>
