<template>
  <div
    class="main-content"
    :class="{
      'shift-left': this.$store.state.ui.shiftLeft,
      'shift-right': this.$store.state.ui.shiftRight,
      pt: mainHeaderVisible && contentTopPadding
    }"
    @mouseenter="mouseOver = true"
    @mouseleave="mouseOver = false"
  >
    <nuxt />
    <main-footer />
    <shift-backdrop />
  </div>
</template>

<script>
import MainFooter from '~/components/main/MainFooter'
import ShiftBackdrop from '~/components/base/ShiftBackdrop'

export default {
  components: {
    MainFooter,
    ShiftBackdrop
  },
  data() {
    return {
      mouseOver: false,
      triggeredScroll: false
    }
  },
  computed: {
    contentShifted() {
      return this.$store.state.ui.shiftLeft || this.$store.state.ui.shiftRight
    },
    mainHeaderVisible() {
      return this.$store.state.ui.mainHeaderVisible
    },
    contentTopPadding() {
      return this.$store.state.ui.contentTopPadding
    },
    navOpen() {
      return this.$store.state.ui.navOpen
    },
    filterOpen() {
      return this.$store.state.ui.filterOpen
    },
    isLargerThanMd() {
      return this.$breakpoints.lMd
    }
  },
  watch: {
    contentShifted(value) {
      if (value) {
        if (this.$store.state.ui.shiftLeft) {
          document.documentElement.classList.add(
            'content-shifted',
            'shift-left',
            'locked'
          )
          if (this.$store.state.ui.filterOpen) {
            document.documentElement.classList.add('filter-open')
          }
        } else if (this.$store.state.ui.shiftRight) {
          document.documentElement.classList.add(
            'content-shifted',
            'shift-right',
            'cart-open'
          )
        }
      } else {
        this.triggeredScroll = false
        document.documentElement.classList.remove(
          'shift-left',
          'shift-right',
          'filter-open',
          'cart-open'
        )
        setTimeout(() => {
          document.documentElement.classList.remove('content-shifted', 'locked')
        }, 400)
      }
    },
    $route() {
      this.$store.commit('ui/closeCart')
    }
  },
  mounted() {
    document.addEventListener('mousewheel', this.scrollCloseNav)
  },
  beforeDestroy() {
    document.removeEventListener('mousewheel', this.scrollCloseNav)
  },
  methods: {
    scrollCloseNav(event) {
      if (
        (this.navOpen && this.isLargerThanMd && this.mouseOver) ||
        (this.filterOpen && this.isLargerThanMd && this.mouseOver)
      ) {
        if (!this.triggeredScroll) {
          this.triggeredScroll = true
          this.$store.commit('ui/closeAll')
        }
      }
    }
  }
}
</script>

<style lang="scss">
.main-content {
  position: relative;
  z-index: 10;
  background: $white;
  min-height: 100vh;
  overflow: hidden;
  left: 0;
  transition-duration: $shift-animation-duration;
  transition-timing-function: $shift-animation-easing;
  // transition-property: transform;
  transition-property: left;
  box-shadow: 0 0 18px rgba(51, 51, 51, 0);

  &.shift-left {
    // transform: translateX(100%);
    left: 100vw;
    box-shadow: 0 0 18px rgba(51, 51, 51, 0.2);
    @include media-breakpoint-up(md) {
      // transform: translateX($nav-desktop-offset);
      left: $nav-desktop-offset;
      .filter-open & {
        left: $filter-desktop-offset;
      }
    }
  }

  &.shift-right {
    // transform: translateX(-100%);
    left: -100vw;

    @include media-breakpoint-up(md) {
      left: 0;
    }
  }

  &.pt {
    padding-top: 52px;
    @include media-breakpoint-up(md) {
      padding-top: 75px;
    }
  }
}

.vue-sticky-el {
  transition-duration: $shift-animation-duration;
  transition-timing-function: $shift-animation-easing;
  transition-property: transform;
}

.content-shifted {
  @include media-breakpoint-down(md) {
    overflow: hidden;
  }

  &.locked {
    overflow: hidden;
  }

  &.shift-left {
    &.filter-open {
      .vue-sticky-el.top-sticky {
        @include media-breakpoint-up(md) {
          transform: translateX(415px);
        }
      }
    }

    .vue-sticky-el.top-sticky {
      transform: translateX(100%);
      // left: 55px !important;
      @include media-breakpoint-up(md) {
        transform: translateX($nav-desktop-offset);
      }
    }
  }
}
.cart-open {
  overflow: hidden;
}
</style>
