import _ from 'lodash'

const defaultState = {
  options: {},
  navObject: [],
  nav: []
}

function createDepthNavObj(pages) {
  const navObj = {}
  for (const i in pages) {
    // if (!pages[i].meta.hide_from_navigation) {
    const structure = _.compact(pages[i].link.replace('/en/', '/').split('/'))
    let base = navObj
    if (pages[i].title === '') {
      pages[i].title = 'Undefined'
    }
    for (const o in structure) {
      if (!base[structure[o]]) {
        base[structure[o]] = {
          page: false,
          hide: pages[i].meta.hide_from_navigation || pages[i].type !== 'page',
          children: {}
        }
        if (parseInt(o) === structure.length - 1) {
          base[structure[o]].page = pages[i]
        }
      }
      base = base[structure[o]].children
    }
    // }
  }
  return navObj
}

function createNavFromDepthObj(navObj) {
  const navArr = []
  for (const i in navObj) {
    navArr.push(getNavNodeFromObj(navObj[i]))
  }
  return _.orderBy(navArr, ['order'])
}

function getNavNodeFromObj(node) {
  const result = {
    label: node.page.title,
    hide: node.hide,
    order: node.page.order
  }
  if (_.size(node.children) > 0) {
    result.children = []
    for (const i in node.children) {
      result.children.push(getNavNodeFromObj(node.children[i]))
    }
    result.to = node.page.link
    result.children = _.orderBy(result.children, ['order'])
  } else {
    result.to = node.page.link
  }
  return result
}

function addBreadcrumpLinks(links, structure, navDepth) {
  const cPathSegment = structure[links.length]
  const cNavDepth = navDepth[cPathSegment]
  if (cPathSegment && cNavDepth) {
    // const link =
    //   links.length + 1 === structure.length ? cNavDepth.page.link : false
    const link = cNavDepth.page.link
    const skip = cNavDepth.page.template === 'page-skip'

    links.push({
      label: cNavDepth.page.title,
      link,
      skip
    })
    addBreadcrumpLinks(links, structure, cNavDepth.children)
  }
}

function resolveSkipLink(link, navDepth) {
  const linkPath = link.replace(/^\/|\/$/g, '').replace(/\//g, '.children.')
  const cPage = _.get(navDepth, linkPath)

  if (cPage && cPage.children) {
    const targetChild = cPage.children[Object.keys(cPage.children)[0]]

    if (targetChild.page.template === 'page-skip') {
      return false
      // return resolveSkipLink(targetChild.page.link, navDepth)
    } else {
      return targetChild.page.link
    }
  } else {
    return false
  }
}

export const state = () => defaultState
export const mutations = {
  setOptions(state, options) {
    state.options = options
  },
  setPages(state, pages) {
    state.navObject = createDepthNavObj(pages)
    state.nav = createNavFromDepthObj(state.navObject)
  }
}

export const actions = {
  async transformSections({ commit }, page) {
    const sections = page.data.layout
    try {
      for (const i in sections) {
        if (
          sections[i].type === 'productgrid' ||
          (sections[i].type === 'look' &&
            sections[i].fields.product_source !== 'products')
        ) {
          sections[i].fields.collection = await this.dispatch(
            'shop/getCollectionById',
            sections[i].fields.collection
          )
          // console.log(sections[i].fields.collection)
        } else if (
          sections[i].type === 'look' &&
          sections[i].fields.product_source === 'products'
        ) {
          sections[i].fields.collection = {}

          const pData = _.chain(sections[i].fields.products)
            .map((product, index) => {
              return JSON.parse(product.product_variant)
            })
            .value()

          const pIDs = _.chain(pData)
            .map((product, index) => {
              return product.pid
            })
            .value()

          sections[i].fields.collection.products = await this.dispatch(
            'shop/getProductsById',
            pIDs
          )

          sections[i].fields.collection.products = _.chain(
            sections[i].fields.collection.products
          )
            .map((product, index) => {
              const variantInfo = _.find(pData, ['pid', product.id])
              if (variantInfo) {
                const vIndex = _.findIndex(product.variants, {
                  id: variantInfo.id
                })
                const activeVariant = product.variants.splice(vIndex, 1)
                product.anchor = vIndex
                product.variants.unshift(activeVariant[0])
              }
              return product
            })
            .value()
        } else if (sections[i].type === 'storiesoverview') {
          const storiesRequest = await this.$axios.get(
            `${process.env.FRONTEND_URL}/_api/stories`,
            {
              params: { lang: this.app.i18n.locale }
            }
          )
          sections[i].fields.stories = storiesRequest.data
        } else if (sections[i].type === 'breadcrumbs') {
          const structure = _.compact(
            page.data.link.replace('/en/', '/').split('/')
          )
          sections[i].fields.links = []
          addBreadcrumpLinks(
            sections[i].fields.links,
            structure,
            this.state.site.navObject
          )

          for (const o in sections[i].fields.links) {
            if (sections[i].fields.links[o].skip) {
              sections[i].fields.links[o].link = resolveSkipLink(
                sections[i].fields.links[o].link,
                this.state.site.navObject
              )
            }
          }
        }
      }
      return page
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }
}
