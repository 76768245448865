<template>
  <media-element :media="media" :lightbox="lightbox" />
</template>

<script>
export default {
  props: {
    image: Object,
    lightbox: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // aspect: 800 / 960,
      aspect: 9 / 16,
      availSizes: {
        pico: 16,
        icon: 32,
        thumb: 50,
        small: 100,
        compact: 160,
        medium: 240,
        large: 480,
        grande: 600,
        x1024: 1024,
        x2048: 2048
      }
    }
  },
  computed: {
    media() {
      const bigImage = this.$shopifyImages.get(this.image.src, {
        size: 'x2048'
      })

      const out = {
        type: 'image',
        seperate_mobile_asset: false,
        is_external_image: false,
        image: {
          alt: this.image.altText,
          caption: '',
          description: '',
          filename: this.image.variableValues
            ? this.image.variableValues.handle + '.jpg'
            : '',
          height: 2048,
          orientation: 'landscape',
          sizes: [],
          title: '',
          url: bigImage,
          width: 2048
        }
      }

      for (const i in this.availSizes) {
        const dimension = Math.round(this.availSizes[i] * this.aspect)
        const path = this.$shopifyImages.get(this.image.src, {
          size: i
        })

        const tempData = {
          filename: 'shopify.jpg',
          height: dimension,
          url: path,
          width: dimension
        }

        out.image.sizes[i] = tempData
      }

      return out
    }
  }
}
</script>
<style lang="scss">
.media-element {
  position: relative;
  width: 100%;
  height: 100%;
  &.is_covered {
    img,
    video {
      object-fit: cover;
    }
  }
  .media-color {
    width: 100%;
    height: 100%;
  }
}
</style>
