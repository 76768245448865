<template>
  <div class="swiper-container">
    <div class="swiper-wrapper">
      <slot></slot>
    </div>
    <slot name="pagination"></slot>
    <slot name="button-prev"></slot>
    <slot name="button-next"></slot>
    <slot name="scrollbar"></slot>
  </div>
</template>

<script>
import { Swiper, Pagination, Navigation } from 'swiper'
export default {
  props: {
    options: {
      type: Object,
      default: () => {}
    }
  },
  mounted() {
    this.init()
  },
  destroyed() {
    this.$swiper.destroy()
    this.$swiper = null
  },
  methods: {
    /**
     * Initialize a new swiper and emit init event.
     */
    init() {
      Swiper.use([Navigation, Pagination])
      this.$swiper = new Swiper(this.$el, this.options)
      this.$emit('ready', this.$swiper)
      this.$swiper.on('click', () => {
        this.$emit('click', this.$swiper)
      })
      this.$swiper.on('slideChange', () => {
        this.$emit('change', this.$swiper)
      })
    },
    /**
     * Return the current swiper instance to access directly
     *
     * @return {swiper}
     */
    swiper() {
      return this.$swiper
    }
  }
}
</script>
