<template>
  <div @click="handle">
    <inner-cart ref="inner" v-if="cartLoaded" />
  </div>
</template>
<script>
import InnerCart from '~/components/shop/InnerCart'
export default {
  components: {
    InnerCart
  },
  data() {
    return {
      cartLoaded: false
    }
  },
  mounted() {
    this.$store.dispatch('shop/loadCheckout').then((response) => {
      this.cartLoaded = true
      if (this.$route.hash === '#cart') {
        this.$nextTick(() => {
          this.$store.commit('ui/openCart')
        })
      }
    })
  },
  methods: {
    handle(e) {
      // if clicked outside of cart drawer then close it
      const cartDrawer = this.$refs.inner.$el.firstElementChild
      if (e.target !== cartDrawer && !cartDrawer.contains(e.target)) {
        this.$store.commit('ui/closeCart')
      }
    }
  }
}
</script>
<style lang="scss"></style>
