<template>
  <header
    class="main-header"
    :class="{
      fixed: fixed,
      'shift-left': this.$store.state.ui.shiftLeft && !isInContent,
      'shift-right': this.$store.state.ui.shiftRight && !isInContent,
      hasBorder: scrolledEnough
    }"
    v-show="showHeader"
  >
    <b-container class="main-header__wrapper">
      <div
        class="main-header__burger"
        @click="$store.commit('ui/toggleNav')"
        :class="{ collapsed: this.$store.state.ui.navOpen }"
      >
        <div class="line" />
      </div>
      <transition>
        <nuxt-link
          class="lille-logo"
          v-show="!logoHidden"
          :to="localePath('/')"
        >
          <Typogram />
        </nuxt-link>
      </transition>
      <transition name="fade">
        <div class="main-header__shopping-cart ">
          <div
            class="desktop lt"
            :class="{
              hide: this.$store.state.ui.shiftRight
            }"
            @click="$store.commit('ui/openCart')"
          >
            {{ $t('shop.cart') }}
            ({{ $store.state.shop.checkout.lineItems.length }})
          </div>
          <div class="mobile" @click="$store.commit('ui/openCart')">
            <ShoppingBagIcon width="18px" />
            <div class="bubble md">
              {{ $store.state.shop.checkout.lineItems.length }}
            </div>
          </div>
        </div>
      </transition>
    </b-container>
    <shift-backdrop />
  </header>
</template>

<script>
import Typogram from '~/assets/svg/Lille_Compagnie_Typogram_black.svg?inline'
import ShoppingBagIcon from '~/assets/svg/shopping-bag-icon.svg?inline'
import ShiftBackdrop from '~/components/base/ShiftBackdrop'
export default {
  name: 'MainHeader',
  components: {
    Typogram,
    ShoppingBagIcon,
    ShiftBackdrop
  },
  props: {
    fixed: {
      type: Boolean,
      default: true
    },
    logoHidden: {
      type: Boolean,
      default: false
    },
    isInContent: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      scrollPos: 0
    }
  },
  computed: {
    showHeader() {
      return this.isInContent ? true : this.$store.state.ui.mainHeaderVisible
    },
    scrolledEnough() {
      return this.scrollPos > 150
    }
  },
  beforeMount() {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      // Your scroll handling here
      this.scrollPos = window.scrollY
    }
  }
}
</script>

<style lang="scss">
.main-header {
  height: 52px;
  width: 100%;
  background: $white;
  z-index: 50 !important;
  position: relative;
  transform: translateX(0) translateY(0);

  transition-duration: $shift-animation-duration;
  transition-timing-function: $shift-animation-easing;
  transition-property: transform;

  .activeOffset & {
    transform: translateY(50px);
  }

  @include media-breakpoint-up(md) {
    height: 75px;
  }

  &.hasBorder {
    .main-header__wrapper {
      border-bottom: 1px solid rgba(#2a2a2a, 0.2);
    }
  }

  &.shift-left {
    transform: translateX(100%);
    @include media-breakpoint-up(md) {
      transform: translate($nav-desktop-offset, 0);
      .activeOffset & {
        transform: translate($nav-desktop-offset, 50px);
      }
      .filter-open & {
        transform: translate($filter-desktop-offset, 0);
        .activeOffset & {
          transform: translate($filter-desktop-offset, 50px);
        }
      }
    }
  }
  &.shift-right {
    @include media-breakpoint-down(md) {
      transform: translateX(-100%);
    }
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 0 1.5rem;
    border-bottom: 1px solid rgba(#2a2a2a, 0);
    transition: border-bottom ease 0.4s;

    @include media-breakpoint-up(sm) {
      padding: 0 0;
      width: calc(100vw - 100px);
      max-width: 100%;
    }
  }

  &__burger {
    width: 28px;
    height: 14px;
    position: relative;
    cursor: pointer;
    margin-top: 1px;

    .line {
      width: 100%;
      height: 2px;
      background: #1c0f0c;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      transition: all 0.125s cubic-bezier(0.455, 0.03, 0.515, 0.955);

      &::before,
      &::after {
        content: '';
        position: absolute;
        left: 0;
        height: inherit;
        width: 100%;
        background: inherit;
        transition: all 0.125s cubic-bezier(0.455, 0.03, 0.515, 0.955);
      }
      &::before {
        top: -6px;
      }
      &::after {
        bottom: -6px;
      }
    }

    @include media-breakpoint-up(md) {
      width: 34px;
      height: 17px;

      .line {
        &::before {
          top: -8px;
        }
        &::after {
          bottom: -8px;
        }
      }
    }

    &.collapsed {
      .line::before {
        transform: translateY(8px);
        opacity: 0;
      }
      .line::after {
        transform: translateY(-8px);
        opacity: 0;
      }
    }
  }

  &__shopping-cart {
    cursor: pointer;

    .desktop {
      margin-top: 8px;
      display: none;
      @include media-breakpoint-up(sm) {
        display: block;

        transition-duration: $shift-animation-duration;
        transition-timing-function: $shift-animation-easing;
        transition-property: opacity;

        &.hide {
          opacity: 0;
        }
      }
    }
    .mobile {
      display: block;
      position: relative;

      .bubble {
        position: absolute;
        top: -10%;
        right: -50%;
        width: 1rem;
        height: 1rem;
        padding-top: 0.2rem;
        padding-left: 0rem;
        background: #000;
        border-radius: 50%;
        color: #fff;
        font-size: 0.75rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0.1rem solid #fff;
        letter-spacing: -1px;
      }

      @include media-breakpoint-up(sm) {
        display: none;
      }
      @include media-breakpoint-down(xs) {
        margin-right: 0.5rem;
      }
    }
  }

  .lille-logo {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 5px;

    svg {
      width: 166px;

      @include media-breakpoint-up(sm) {
        width: 240px;
      }
    }

    &.hidden {
      display: none;
    }
  }

  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100 !important;
    box-shadow: -0px 0px 4px rgba(51, 51, 51, 0) !important;
  }

  .v-leave-active,
  .v-enter-active {
    transition: all 0.2s cubic-bezier(0.77, 0, 0.175, 1);
  }
  .v-leave-to,
  .v-enter {
    opacity: 0;
    transform: translate(-50%, -70%);
  }
}
</style>
