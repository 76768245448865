const defaultState = {
  filterData: false,
  activeFilter: [],
  filterNav: [],
  filterProducts: [],
  optionsMatches: {
    color: ['color', 'colors', 'farbe'],
    size: ['size', 'sizes', 'größe']
  },
  filterOptions: ['size', 'brand', 'color']
}

export const state = () => defaultState
export const mutations = {
  updateActiveFilter(state, payload) {
    state.activeFilter = payload
  },
  updateFilterData(state, payload) {
    state.filterData = payload
  },
  updateFilterNav(state, payload) {
    state.filterNav = payload
  },
  updateFilterProducts(state, payload) {
    state.filterProducts = payload
  }
}
