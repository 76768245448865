import Vue from 'vue'
import Sticky from 'vue-sticky-directive'
import lineClamp from 'vue-line-clamp'
import { VBScrollspyPlugin } from 'bootstrap-vue'
import VueMasonry from 'vue-masonry-css'
import PhotoSwipe from 'photoswipe/dist/photoswipe'
import PhotoSwipeUI from 'photoswipe/dist/photoswipe-ui-default'
import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'
import createPreviewDirective from 'vue-photoswipe-directive'

import winResize from '~/directives/win-resize'

Vue.use(Sticky)
Vue.use(VBScrollspyPlugin)
Vue.use(lineClamp)
Vue.use(VueMasonry)
Vue.directive(
  'preview',
  createPreviewDirective(
    {
      //   hideAnimationDuration: 0,
      //   showAnimationDuration: 0,
      showHideOpacity: true,
      shareEl: false,
      fullscreenEl: false,
      zoomEl: false
    },
    PhotoSwipe,
    PhotoSwipeUI
  )
)

Vue.directive('win-resize', winResize)
