import Vue from 'vue'
import { Swiper, Pagination } from 'swiper'
import SwiperComponent from './swiper'
import SwiperSlideComponent from './swiper-slide'

import 'swiper/swiper-bundle.css'

Swiper.use(Pagination)
Vue.component('Swiper', SwiperComponent)
Vue.component('SwiperSlide', SwiperSlideComponent)
