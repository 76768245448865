<template>
  <div class="app" :class="{ activeOffset: hasNewsAlert }">
    <news-banner v-if="hasNewsAlert" />
    <main-header />
    <filter-nav />
    <main-nav />
    <cart />
    <main-content />
    <CookieConsent />
  </div>
</template>
<script>
import MainHeader from '~/components/main/MainHeader'
import MainNav from '~/components/main/MainNav'
import FilterNav from '~/components/filter/FilterNav'
import Cart from '~/components/shop/Cart'
import MainContent from '~/components/main/MainContent'
import CookieConsent from '~/components/main/CookieConsent'
import NewsBanner from '~/components/main/NewsBanner'
export default {
  name: 'DefaultLayout',
  components: {
    MainHeader,
    MainNav,
    FilterNav,
    Cart,
    MainContent,
    CookieConsent,
    NewsBanner
  },
  data() {
    return {
      hasNewsAlert: this.$store.state.site.options.news_alert.show_alert
    }
  }
}
</script>
<style lang="scss">
.app {
  overflow-x: hidden;
  max-width: 100vw;
  width: 100%;
  box-shadow: 0px 0px 13px -1px #000000;
}
</style>
