<template>
  <div class="back-to-top" ref="backtotop">
    <a href="#" @click.prevent="jumptotop"><arrow-up /></a>
  </div>
</template>

<script>
import { gsap, Power4 } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import arrowUp from '~/assets/svg/arrow-up.svg?inline'
if (process.client) {
  gsap.registerPlugin(ScrollToPlugin)
  gsap.registerPlugin(ScrollTrigger)
}

export default {
  name: 'BackToTop',
  components: {
    arrowUp
  },
  props: {},
  data() {
    return {
      scrollTr: false,
      thisOffset: 0,
      progress: 0
    }
  },
  computed: {
    topOffset() {
      const offset = window.innerHeight
      return offset
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initScrollTrigger()
      this.oberveHeight()
    })
    // if (process.browser) {
    //   window.addEventListener('load', () => {
    //     console.log(
    //       'height: ',
    //       document.querySelector('.main-content').clientHeight
    //     )
    //   })
    // }
  },

  methods: {
    jumptotop() {
      gsap.to(window, 0.7, {
        scrollTo: { y: 0, autoKill: false },
        ease: Power4.easeOut
      })
    },
    oberveHeight() {
      const resizeObserver = new ResizeObserver(() => {
        // window.dispatchEvent(new Event('resize'))
        this.scrollTr.refresh(true)
      })
      resizeObserver.observe(document.querySelector('.main-content'))
    },
    initScrollTrigger() {
      const el = document.querySelector('.back-to-top')
      this.thisOffset = this.topOffset
      this.scrollTr = ScrollTrigger.create({
        id: 'b2t',
        trigger: 'body',
        start: 'top+=' + this.thisOffset + 'px',
        endTrigger: '.footer-section',
        end: 'top bottom',
        onEnter: ({ progress, direction, isActive }) =>
          el.classList.add('slide-in'),
        onLeaveBack: ({ progress, direction, isActive }) =>
          el.classList.remove('slide-in'),
        onLeave: ({ progress, direction, isActive }) => el.classList.add('end'),
        onEnterBack: ({ progress, direction, isActive }) =>
          el.classList.remove('end')
      })
    }
  }
}
</script>

<style lang="scss">
.back-to-top {
  display: flex;
  justify-content: center;
  width: 100%;
  transition: transform ease 0.3s;
  transform: translateY(calc(100% + 20px));
  position: fixed;
  z-index: 300000;
  bottom: 16px;
  top: auto;
  @include media-breakpoint-up(md) {
    right: 16px;
    width: 70px;
  }
  &.slide-in {
    transform: translateY(0);
    transition: transform ease 0.3s;
  }
  &.end {
    transform: translateY(calc(100% + 20px));
    @include media-breakpoint-up(md) {
      position: absolute;
      top: -86px;
      transform: translateY(0);
    }
  }
  a {
    display: inline-block;
    width: 54px;
    height: 54px;
    position: relative;
    border-radius: 50%;
    background-color: $white;
    box-shadow: 0 2px 17px 0 rgba(0, 0, 0, 0.05);
    transition: box-shadow ease 0.4s;
    @include media-breakpoint-up(md) {
      width: 70px;
      height: 70px;
      margin: 0;
    }
    &:hover {
      box-shadow: 0 2px 17px 0 rgba(0, 0, 0, 0.2);
      svg {
        transform: translate(-50%, -50%) scale3d(1, 1.1, 1);
      }
    }
    svg {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      transition: transform 0.4s ease;
      transform-origin: bottom center;
      transform: translate(-50%, -50%) scale3d(1, 1, 1);
      height: 24px;
      @include media-breakpoint-up(md) {
        height: 30px;
      }
    }
  }
}
</style>
