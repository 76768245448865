<template>
  <base-section class="section footer-section">
    <client-only>
      <back-to-top />
    </client-only>
    <b-container>
      <b-row class="about">
        <b-col cols="12" md="5">
          <h4>{{ fields.headline }}</h4>
          <div v-html="fields.copy" style="line-height:calc(19/16)"></div>
        </b-col>
        <b-col cols="12" md="6" offset-md="1">
          <ul>
            <li v-for="item in fields.facts" :key="item.item" class="h2">
              {{ item.item }}
            </li>
          </ul>
        </b-col>
      </b-row>
    </b-container>
    <div class="footer-border">
      <b-container>
        <b-row class="footer-nav">
          <b-col cols="12" md="1" class="brand">
            <div class="logo-wrapper">
              <LilleSignet class="logo" />
            </div>
            <div class="social-media-mobile">
              <div class="align">
                <div class="top">
                  {{ footer.social.headline }}
                </div>
                <div class="bottom">
                  <a
                    v-for="link in footer.social.links"
                    :key="link.id"
                    :href="link.link"
                    target="_blank"
                    >{{ link.label }}
                  </a>
                </div>
              </div>
            </div>
          </b-col>
          <b-col cols="12" md="2" class="nav-column">
            <b>{{ fields.nav.column_1.title }}</b>
            <nav>
              <base-link
                v-for="item in fields.nav.column_1.items"
                :key="item.link.label"
                :linkdata="item.link"
              >
                {{ item.link.label }}
              </base-link>
            </nav>
          </b-col>
          <b-col cols="12" md="2" class="nav-column">
            <b>{{ fields.nav.column_2.title }}</b>
            <nav>
              <base-link
                v-for="item in fields.nav.column_2.items"
                :key="item.link.label"
                :linkdata="item.link"
              >
                {{ item.link.label }}
              </base-link>
            </nav>
          </b-col>
          <b-col cols="12" md="2" class="nav-column">
            <b>{{ fields.nav.column_3.title }}</b>
            <nav>
              <base-link
                v-for="item in fields.nav.column_3.items"
                :key="item.link.label"
                :linkdata="item.link"
              >
                {{ item.link.label }}
              </base-link>
            </nav>
          </b-col>
          <b-col cols="12" md="auto" class="ml-auto social-media">
            <div class="align">
              <div class="top">
                {{ footer.social.headline }}
                <a
                  v-for="link in footer.social.links"
                  :key="link.link"
                  :href="link.link"
                  target="_blank"
                  >{{ link.label }}
                </a>
              </div>
              <div class="bottom copyright">&copy; Lille Compagnie</div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </base-section>
</template>

<script>
import LilleSignet from '~/assets/svg/Lille_Compagnie_Signet_black.svg?inline'
import BackToTop from '~/components/main/BackToTop'

export default {
  components: {
    LilleSignet,
    BackToTop
  },
  data() {
    return {
      footer: {
        social: {
          headline: 'Social Media',
          links: [
            {
              link: 'https://www.facebook.com/lillecompagnie/',
              label: 'Fb'
            },
            {
              link: 'https://www.instagram.com/lillecompagnie/',
              label: 'Ig'
            }
          ]
        }
      }
    }
  },
  computed: {
    fields() {
      return this.$store.state.site.options.footer
    }
  }
}
</script>

<style lang="scss">
.base-section.footer-section {
  background: #fdf8f1;
  font-weight: 300;
  padding: 0;
  position: relative;
  @include media-breakpoint-up(md) {
    padding: 0 0 48px 0;
  }
  .about {
    padding: 80px 0 0 0;
    @include media-breakpoint-up(md) {
      padding: 177px 0 0 0;
    }
    h4 {
      margin-bottom: 20px;
    }
    ul {
      margin: 22px 0 0 0;
      @include media-breakpoint-up(md) {
        margin-top: -10px;
      }
      li {
        font-family: $font-family-serif;
        font-weight: $headings-font-weight;
        line-height: 1;
        list-style: none;
        margin-bottom: 30px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .footer-border {
    border-top: 1px solid #d8d8d8;
    margin-top: 83px;
    @include media-breakpoint-up(md) {
      margin-top: 200px;
    }
  }
  .footer-nav {
    @include media-breakpoint-up(md) {
      padding-top: 43px;
    }
    .brand {
      border-bottom: 1px solid #d8d8d8;
      padding-top: 32px;
      padding-bottom: 32px;
      display: flex;
      justify-content: space-between;
      @include media-breakpoint-up(md) {
        border-bottom: none;
        padding-top: 0px;
        padding-bottom: 0px;
      }
      > div {
        width: 50%;
      }
      .logo {
        height: auto;
        width: 46px;
        @include media-breakpoint-up(md) {
          width: 32px;
        }
      }
      .social-media-mobile {
        @include media-breakpoint-up(md) {
          display: none;
        }
        .align {
          display: flex;
          flex-direction: column;
          height: 100%;
          justify-content: space-between;
        }
        a {
          display: inline-block;
          font-weight: 500;
          margin-right: 54px;
        }
      }
    }
    .nav-column {
      border-bottom: 1px solid #d8d8d8;
      @include media-breakpoint-down(md) {
        padding-right: 0;
      }
      @include media-breakpoint-up(md) {
        border-bottom: none;
      }
      overflow: hidden;
      b {
        display: block;
        float: left;
        font-weight: 500;
        margin-bottom: 24px;
        padding: 32px 0;
        width: calc(50% - 0.75rem);
        @include media-breakpoint-up(md) {
          float: none;
          margin-bottom: 30px;
          padding: 0;
          width: auto;
        }
      }
      nav {
        float: right;
        width: calc(50% + 0.75rem);
        @include media-breakpoint-up(md) {
          float: none;
          width: auto;
        }
        a {
          border-bottom: 1px solid #d8d8d8;
          display: block;
          padding: 32px 0;
          &:last-child {
            border-bottom: 0;
          }
          @include media-breakpoint-up(md) {
            border: none;
            margin-bottom: 10px;
            padding: 0;
          }
        }
      }
    }
    .social-media {
      padding-top: 32px;
      padding-bottom: 32px;
      @include media-breakpoint-up(md) {
        padding-top: 0;
        padding-bottom: 0;
        text-align: right;
      }
      .align {
        @include media-breakpoint-up(md) {
          display: flex;
          flex-direction: column;
          height: 100%;
          justify-content: space-between;
        }
        .top {
          display: none;
          @include media-breakpoint-up(md) {
            display: block;
          }
          a {
            display: inline-block;
            font-weight: 500;
            margin-left: 40px;
          }
        }
        .copyright {
          float: right;
          width: 50%;
          @include media-breakpoint-up(md) {
            float: none;
            width: auto;
          }
        }
      }
    }
  }
}
</style>
