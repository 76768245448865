<template>
  <CookieControl class="consent-wrapper" :locale="this.$i18n.locale" />
</template>
<script>
import CookieControl from '~/components/main/CookieControl'

export default {
  name: 'CookieConsent',
  components: {
    CookieControl
  },

  data() {
    return {}
  }
}
</script>
<style lang="scss">
.cookieControl {
  max-width: 1024px;
  z-index: 9999;
  background-color: $white;
  &__ControlButton {
    left: 20px;
    right: auto;
    transition: background ease 0.4s;
    display: none;
    @include media-breakpoint-down(xs) {
      display: none;
    }
    &:hover {
      svg {
        path {
          fill: $white;
        }
      }
    }
    svg {
      path {
        transition: fill ease 0.4s;
        fill: $black;
      }
    }
  }
  &__Bar {
    left: 50%;
    transform: translateX(-50%);
    right: auto;
    bottom: 4vh;
    background-color: $white;
    box-shadow: 0px 3px 12px 1px rgba(0, 0, 0, 0.23);

    @include media-breakpoint-down(xs) {
      bottom: 2%;
      width: 96%;
    }
    &Container {
      padding: 0;
      display: block;
      > div {
        width: 100%;
        position: relative;
        p {
          padding: 12px 30px 18px;
          max-width: none;
          font-size: 13px;
          color: $black;
        }
      }
    }
    &Buttons {
      margin-top: 0;
      width: 100%;
      position: relative;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;

      &:before {
        content: '';
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
        height: 1px;
        background: #dcdcdc;
      }
    }
  }
  &__Modal {
    &:after {
      background-color: rgba($white, 0.9);
    }

    &Close {
      position: absolute;
      padding: 16px !important;
      background: transparent !important;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        display: block;
        width: 2px;
        height: 30px;
        background-color: $black;
        transform: rotate(45deg);
      }
      &:after {
        content: '';
        position: absolute;
        top: 0;
        display: block;
        width: 2px;
        height: 30px;
        background-color: $black;
        transform: rotate(-45deg);
      }
    }
    &InputWrapper {
      display: flex;
      align-items: center;
      flex-flow: row wrap;
    }
    &Content {
      padding: 0 0;
      padding-top: 50px !important;
      background-color: $white;
      box-shadow: 0px 3px 12px 1px rgba(0, 0, 0, 0.23);
      color: $black;
      > div {
        h3 {
          font-size: $h5-font-size;
          margin: 1.5em 0 0.75em;
          padding: 0 45px;
          letter-spacing: 0;
          color: $black;
        }
        ul {
          padding: 0 45px;
          margin: 0;
          ul {
            padding: 5px 0;
            margin: 0;
            li {
              background: rgba(0, 0, 0, 0.1);
              border-radius: 20px;
              padding: 2px 10px;
              display: inline-block;
              margin-right: 10px;
              font-size: 12px;
              line-height: 20px;
              padding-top: 5px;
              color: $black;
            }
          }
        }
      }
      label {
        margin-bottom: 0;
        color: $black;
      }
      input:checked + label:before {
        background-color: $black;
        color: $black;
      }
      input:checked:disabled + label:before {
        background-color: rgba($black, 0.3);
        color: $black;
      }
      input:checked + label {
        background-color: var(--cookie-control-checkboxDisabledBackground);
      }
    }
    &CookieName {
      text-transform: none;
      font-size: 13px;
      div,
      span {
        color: $black;
      }
    }
    &Buttons {
      border-top: 1px solid #dcdcdc;
      display: flex;
      margin-top: 40px;
      @include media-breakpoint-down(sm) {
        flex-flow: row wrap;
      }
    }
    &Unsaved {
      display: none;
    }
  }

  button {
    flex: 1;
    margin: 0 !important;
    padding: 1.3rem 0 1rem;
    white-space: nowrap;
    background: $white;
    color: $black;
    font-size: 14px;
    &:first-of-type {
      &:hover {
        background-color: $black;
        color: $white;
      }
    }

    &.accept-all,
    &.decline-all {
      background: $black;
      color: $white;

      &:hover {
        background: #fff;
        color: $black;
      }
    }
  }

  @include media-breakpoint-down(md) {
    flex-flow: column nowrap;
  }
}
</style>
