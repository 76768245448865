<template>
  <transition name="visibility">
    <div class="main-nav-wrapper">
      <nav
        v-show="this.$store.state.ui.navOpen"
        class="main-nav"
        v-win-resize="onResize"
      >
        <div
          class="main-nav__line"
          @click="$store.commit('ui/toggleNav', false)"
        >
          <div class="line" />
        </div>
        <div class="main-nav__upper">
          <nuxt-link :to="localePath('/')">
            <LilleSignet class="signet" />
          </nuxt-link>
          <div class="lang-switcher">
            <a
              v-for="locale in $i18n.locales"
              :key="locale.code"
              :href="switchLocalePath(locale.code)"
              :class="{ 'lang-active': locale.code === $i18n.locale }"
              class="md lang"
              >{{ locale.name }}</a
            >
          </div>
        </div>
        <div class="main-nav__menu">
          <!-- first level -->
          <ul class="submenu lv1">
            <li
              v-for="(item, i) in menu"
              :key="i"
              class="link"
              :class="{
                inactive:
                  !openSubmenu.includes(item.to) &&
                  openSubmenu !== '' &&
                  openSubmenu !== '/en/'
              }"
              v-show="!item.hide"
            >
              <!-- first level: link -->
              <nuxt-link
                v-if="
                  (item.children === undefined &&
                    item.to !== undefined &&
                    !item.hide) ||
                    areChildrenHidden(item.children)
                "
                :to="item.to"
                class="label h5"
                ><span>{{ item.label }}</span></nuxt-link
              >

              <!-- first level: submenu -->
              <template v-else-if="Array.isArray(item.children) && !item.hide">
                <div
                  class="label h5 line"
                  @click="handleSubmenu(item.to)"
                  key="label-lv1"
                  :class="{
                    inactive:
                      !openSubmenu.includes(item.to) &&
                      openSubmenu !== '' &&
                      openSubmenu !== '/en/'
                  }"
                >
                  <!-- first level: label -->
                  <span>{{ item.label }}</span>
                </div>
                <transition name="expanding" mode="out-in">
                  <!-- second level -->
                  <ul
                    class="submenu lv2"
                    key="labels-lv2"
                    v-if="openSubmenu.includes(item.to) && !item.hide"
                  >
                    <li
                      v-for="(child, j) in item.children"
                      :key="j"
                      :class="{
                        active: openSubmenu.includes(child.to),
                        line: j !== item.children.length - 1,
                        inactive: lvl2Inactive(child.to)
                      }"
                      v-show="!child.hide"
                    >
                      <!-- second level: link -->
                      <nuxt-link
                        v-if="
                          (child.children === undefined &&
                            child.to !== undefined &&
                            !child.hide) ||
                            areChildrenHidden(child.children)
                        "
                        :to="child.to"
                        class="label h5"
                        ><span>{{ child.label }}</span></nuxt-link
                      >

                      <div
                        class="label h5"
                        @click="handleSubmenu(child.to)"
                        v-else
                      >
                        <!-- second level: label -->
                        <span>{{ child.label }}</span>
                      </div>

                      <!-- third level -->
                      <transition-group
                        tag="ul"
                        class="submenu lv3"
                        :class="{ mb: openSubmenu.includes(child.to) }"
                        @before-enter="staggerBeforeEnter"
                        @enter="staggerEnter"
                        @leave="staggerLeave"
                        :css="false"
                      >
                        <li
                          v-for="(link, k) in child.children"
                          class="label sm lt"
                          :key="link.label + k"
                          :data-index="k"
                          :class="{ spacer: link.spacer }"
                          v-show="openSubmenu.includes(child.to)"
                        >
                          <div v-if="link.spacer">&nbsp;</div>
                          <nuxt-link v-else :to="{ path: link.to }">
                            <!-- third level: link -->
                            {{ link.label }}
                          </nuxt-link>
                        </li>
                      </transition-group>
                    </li>
                  </ul>
                </transition>
              </template>
            </li>
          </ul>
        </div>
      </nav>
      <div class="main-nav__lower mobile">
        <nuxt-link :to="localePath('/')">
          <LilleSignet class="signet" />
        </nuxt-link>
        <div class="lang-switcher">
          <a
            v-for="locale in $i18n.locales"
            :key="locale.code"
            :href="switchLocalePath(locale.code)"
            :class="{ 'lang-active': locale.code === $i18n.locale }"
            class="md lang"
            >{{ locale.name }}</a
          >
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { gsap } from 'gsap'
import LilleSignet from '~/assets/svg/Lille_Compagnie_Signet_black.svg?inline'
export default {
  components: {
    LilleSignet
  },
  props: {},
  data() {
    return {
      openSubmenu: '',
      regex: new RegExp(/[\w-]+(?=[/\r\n?]|$)/g)
    }
  },
  computed: {
    menu() {
      // Will be replaced by dynamic data from CMS
      // return this.$t('menu')
      return this.$store.state.site.nav
    },
    navOpen() {
      return this.$store.state.ui.navOpen
    },
    isMobile() {
      return this.$breakpoints.sSm
    }
  },
  watch: {
    $route() {
      this.$store.commit('ui/closeNav')
      const pathArr = this.$route.path.match(this.regex)
      if (pathArr) {
        pathArr.pop()

        setTimeout(() => {
          this.openSubmenu =
            pathArr.join('/') !== '' && !pathArr.join('/').includes('product')
              ? `/${pathArr.join('/')}/`
              : ''
        }, 400)
      }
    }
  },
  methods: {
    onResize() {
      if (this.$breakpoints.lMd) {
        this.$store.commit('ui/closeAll')
      }
    },
    handleSubmenu(sub) {
      if (this.openSubmenu === sub) {
        if (this.openSubmenu.match(this.regex)[0] === 'en') {
          if (this.openSubmenu.match(this.regex)[2] !== undefined) {
            this.openSubmenu = `/en/${this.openSubmenu.match(this.regex)[1]}/`
          } else {
            this.openSubmenu = ''
          }
        } else if (this.openSubmenu.match(this.regex)[1] !== undefined) {
          this.openSubmenu = `/${this.openSubmenu.match(this.regex)[0]}/`
        } else {
          this.openSubmenu = ''
        }
      } else {
        this.openSubmenu = sub
      }
    },
    lvl2Inactive(child) {
      if (this.openSubmenu.includes('/en/')) {
        return (
          !this.openSubmenu.includes(child) &&
          this.openSubmenu.match(this.regex)[2] !== undefined
        )
      }
      return (
        !this.openSubmenu.includes(child) &&
        this.openSubmenu.match(this.regex)[1] !== undefined
      )
    },
    staggerBeforeEnter(el) {
      el.style.opacity = 0
      el.style.height = 0
    },
    staggerEnter(el, done) {
      const delay = el.dataset.index * 30
      const that = this
      setTimeout(() => {
        gsap.to(el, 0.25, {
          opacity: 1,
          height: that.isMobile ? 56 : 38,
          ease: 'Power2.easeOut',
          onComplete: done
        })
      }, delay)
    },
    staggerLeave(el, done) {
      const delay = el.dataset.index * 10
      setTimeout(() => {
        gsap.to(el, 0.1, {
          opacity: 0,
          height: 0,
          ease: 'Power2.easeIn',
          onComplete: done
        })
      }, delay)
    },
    areChildrenHidden(children) {
      if (Array.isArray(children)) {
        if (children.filter((child) => child.hide).length === children.length) {
          return true
        }
      }

      return false
    }
  }
}
</script>

<style lang="scss">
$line-color: #e8e7e6;

.main-nav {
  height: 100vh;
  position: fixed;
  left: 0;
  width: 100vw;
  z-index: 1;
  padding: 26px 0;
  overflow-y: auto;
  transform: translateY(0);
  transition: transform ease 0.4s;
  .activeOffset & {
    transform: translateY(50px);
  }

  @include media-breakpoint-up(md) {
    width: 500px;
  }

  &__line {
    width: 28px;
    height: 14px;
    position: absolute;
    right: 1.2rem;
    top: 19px;
    cursor: pointer;
    display: block;

    @include media-breakpoint-up(md) {
      width: 34px;
      height: 17px;
      top: 1.8rem;
    }

    .line {
      width: 100%;
      height: 2px;
      background: #1c0f0c;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  &__upper,
  &__lower {
    align-items: center;
    padding-left: 100px;
    padding-right: 25px;
    margin-bottom: 65px;
    display: none;

    @include media-breakpoint-up(sm) {
      display: flex;
    }

    @include media-breakpoint-up(md) {
      margin-bottom: 85px;
    }

    .signet {
      height: auto;
      width: 26px;

      @include media-breakpoint-up(sm) {
        margin-right: 60px;
      }

      @include media-breakpoint-up(md) {
        width: 32px;
        margin-right: 128px;
      }
    }
    .lang-switcher {
      .lang {
        color: #aba99d;
        position: relative;
        display: inline-block;
        padding-right: 0.75rem;
        padding-left: 0.75rem;

        &:after {
          position: absolute;
          top: 8%;
          right: 0rem;
          height: 65%;
          width: 2px;
          background-color: $text-color;
          content: '';
        }
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
          &:after {
            display: none;
          }
        }

        &.lang-active {
          color: #1c0f0c;
        }
      }
    }
  }
  &__lower {
    display: flex;
    position: absolute;
    bottom: 1.2rem;
    left: 1.2rem;
    width: calc(100% - 2.4rem);
    justify-content: space-between;
    padding: 0;
    margin: 0;

    @include media-breakpoint-up(sm) {
      display: none;
    }

    &.static {
      position: static;
      margin: 2.4rem auto 0;
    }
    &.desktop {
      display: none;
      @include media-breakpoint-up(sm) {
        display: none;
      }
    }
    &.mobile {
      display: flex;
      position: fixed;
      bottom: 0;
      background-color: $white;
      // width: 100%;
      z-index: 10;
      width: 100%;
      background-color: $white;
      left: 0;
      padding: 24px 1.2rem;
      .lang-switcher {
        .lang {
          &.active {
            color: #1c0f0c;
          }
        }
      }
      @include media-breakpoint-up(sm) {
        display: none;
      }
    }
  }
  &__menu {
    margin-top: 25px;
    padding-bottom: 50px;
    @include media-breakpoint-up(sm) {
      margin-top: 0;
      padding-bottom: 0;
    }
    ul {
      margin: 0;
      padding: 0;

      li {
        list-style: none;
      }
    }

    .inactive {
      color: rgba($text-color, 0.4);
    }

    .link {
      border-bottom: 1px solid $line-color;
      padding-left: 1.2rem;

      @include media-breakpoint-up(sm) {
        padding-left: 100px;
      }

      &:first-child {
        padding-top: 0;

        .h5 {
          padding-top: 23px;
        }
      }
    }

    .label {
      transition: 0.15s color ease-in-out;

      span {
        font-family: 'minerva-modern', serif;
        position: relative;
        display: inline-block;
        &:after {
          content: '';
          position: absolute;
          left: 0;
          bottom: 2px;
          background: $text-color;
          width: 100%;
          height: 1px;
          opacity: 0;
          transition: 0.15s all ease-in-out;
        }
      }

      @include media-breakpoint-up(sm) {
        &:hover {
          color: rgba($text-color, 1);
          text-decoration: none;

          span:after {
            opacity: 1;
          }
        }
      }

      &.h5 {
        display: inline-block;
        width: 100%;
        padding: 25px 0;
        cursor: pointer;
        margin-bottom: 0;
      }
      &.hidden {
        display: none;
      }
      &.sm {
        letter-spacing: 0.5px;
        line-height: 2.375;

        .exact-active {
          color: $text-color;
          text-decoration: underline;
        }

        &.spacer {
          height: 1em !important;
          cursor: normal !important;
        }
      }
      &.line {
        position: relative;
        &:after {
          position: absolute;
          bottom: -1px;
          left: -1.2rem;
          width: 100vw;
          height: 1px;
          content: '';
          background: $line-color;

          @include media-breakpoint-up(md) {
            left: -100px;
            width: calc(100px + 100%);
          }
        }
      }

      &.exact-active {
        span {
          color: $text-color;
          &:after {
            opacity: 1;
          }
        }
      }
    }

    .submenu {
      &.hidden {
        display: none;
      }

      &.lv2 {
        .line {
          border-bottom: 1px solid rgba($text-color, 0.1);
        }
      }
      &.lv3 {
        margin-bottom: 0;
        transition: 0.2s margin ease-in-out;
        &.mb {
          margin-bottom: 1.5rem;
        }
      }
    }
  }

  .expanding-enter-active,
  .expanding-leave-active {
    transition: max-height 0.3s linear, opacity 0.2s ease-in-out;
    max-height: 510px;
  }
  .expanding-enter, .expanding-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
    max-height: 0;
  }
}
</style>
