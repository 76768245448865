const defaultState = {
  cartOpen: false,
  navOpen: false,
  filterOpen: false,
  shiftLeft: false,
  shiftRight: false,
  mainHeaderVisible: true,
  contentTopPadding: true
}

function updateShifts(state) {
  if (state.navOpen || state.filterOpen) {
    state.shiftLeft = true
    state.shiftRight = false
  } else if (state.cartOpen) {
    state.shiftLeft = false
    state.shiftRight = true
  } else {
    state.shiftLeft = false
    state.shiftRight = false
  }
}

export const state = () => defaultState
export const mutations = {
  closeAll(state) {
    state.cartOpen = false
    state.navOpen = false
    state.filterOpen = false
    updateShifts(state)
  },
  openCart(state) {
    state.cartOpen = true
    updateShifts(state)
  },
  closeCart(state) {
    state.cartOpen = false
    updateShifts(state)
  },
  toggleCart(state) {
    state.cartOpen = !state.cartOpen
    updateShifts(state)
  },
  openNav(state) {
    state.navOpen = true
    updateShifts(state)
  },
  closeNav(state) {
    state.navOpen = false
    updateShifts(state)
  },
  toggleNav(state) {
    state.navOpen = !state.navOpen
    updateShifts(state)
  },
  openFilter(state) {
    state.filterOpen = true
    updateShifts(state)
  },
  closeFilter(state) {
    state.filterOpen = false
    updateShifts(state)
  },
  toggleFilter(state) {
    state.filterOpen = !state.navOpen
    updateShifts(state)
  },

  showMainHeader(state, payload) {
    if (payload !== undefined) {
      state.mainHeaderVisible = payload
    }
  },
  setContentTopPadding(state, payload) {
    if (payload !== undefined) {
      state.contentTopPadding = payload
    }
  }
}
