<template>
  <transition name="fade-background">
    <div v-show="this.$store.state.ui.cartOpen" class="cart">
      <transition name="slide">
        <div class="cart-drawer" v-show="this.$store.state.ui.cartOpen">
          <div class="cart-drawer__container">
            <div class="cart-drawer__header">
              <div class="w-100">
                <b-row align-v="center" no-gutters>
                  <div
                    class="cart-drawer__close"
                    @click="$store.commit('ui/closeCart')"
                  >
                    <div class="line" />
                  </div>
                  <div class="cart-drawer__headline">
                    {{ $t('shop.cart') + ` (${checkout.lineItems.length})` }}
                  </div>
                </b-row>
              </div>
            </div>
            <div class="cart-drawer__body">
              <div
                class="empty"
                v-if="$store.state.shop.checkout.lineItems.length == 0"
              >
                {{ $t('shop.empty') }}
              </div>
              <div class="line-items" v-else>
                <line-item
                  v-for="item in checkout.lineItems"
                  :key="item.id"
                  :item="item"
                  @processing="(value) => (processing = value)"
                />
              </div>
            </div>
            <div class="cart-drawer__footer">
              <b-row align-v="start" no-gutters>
                <b-col cols="4" md="6">
                  <span class="total-text"> {{ $t('shop.total') }}</span>
                </b-col>
                <b-col cols="8" md="6" class="total">
                  <div class="md">{{ totalPrice }}</div>
                  <div class="vat-info pt-1">{{ $t('shop.vatText') }}</div>
                </b-col>
              </b-row>
              <a
                :href="
                  $queryString.stringifyUrl({
                    url: checkout.webUrl.replace(
                      'lille-compagnie.myshopify.com',
                      'checkout.lillecompagnie.com'
                    ),
                    query: { locale: $i18n.locale }
                  })
                "
                target="_blank"
              >
                <button
                  class="btn full"
                  :disabled="checkout.lineItems <= 0 || processing"
                  block
                >
                  {{ $t('shop.checkout') }}
                </button>
              </a>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>
<script>
import LineItem from '~/components/shop/LineItem'
export default {
  components: {
    LineItem
  },
  data() {
    return {
      processing: false
    }
  },
  computed: {
    active() {
      return this.$store.state.ui.cartOpen
    },
    checkout() {
      return this.$store.state.shop.checkout
    },
    totalPrice() {
      return new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR'
      }).format(this.checkout.totalPrice.amount)
    }
  },
  watch: {
    $route() {
      this.$store.commit('ui/closeCart')
    }
  }
}
</script>
<style lang="scss">
.cart {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 101;
  background: rgba(0, 0, 0, 0.5);

  .cart-drawer {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    background-color: $white;
    overflow: hidden;

    @include media-breakpoint-up(md) {
      width: 548px;
    }

    &__container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    &__header {
      width: 100%;
      display: flex;
      max-height: 52px;
      align-items: center;
      padding: 14px 20px;
      border-bottom: 1px solid #d3d3d3;
      @include media-breakpoint-up(md) {
        max-height: 75px;
        padding: 25px 32px;
      }
    }

    &__headline {
      padding-left: 24px;
      padding-top: 3px;
    }

    &__body {
      width: 100%;
      flex-grow: 1;
      padding: 36px 20px;
      overflow-y: auto;
      display: flex;

      @include media-breakpoint-up(md) {
        padding-left: 32px;
        padding-right: 39px;
        padding-top: 57px;
        padding-bottom: 57px;
      }

      .empty {
        font-size: 30px;
        line-height: 36px;
        letter-spacing: -0.86px;
        font-weight: 400;
        color: #c2c2c2;
        align-self: center;
        margin: auto;
        text-align: center;
      }
      .line-items {
        width: 100%;
      }
      .line-item:first-child {
        padding-top: 0;
      }
    }

    &__footer {
      width: 100%;
      padding: 18px 20px;
      background: #fff;
      box-shadow: 0 2px 13px 0px rgba(0, 0, 0, 0.11);
      font-size: 13px;

      @include media-breakpoint-up(md) {
        font-size: 1rem;
        padding: 25px 32px;
      }

      .total {
        text-align: right;
      }
      .total-text {
        font-weight: 500;

        @include media-breakpoint-up(md) {
          font-weight: 300;
        }
      }

      .btn.full {
        width: 100%;
        margin-top: 0.75rem;
        background: rgba($text-color, 1);
        color: #fff;
        text-align: center;
        border: 1px solid rgba($text-color, 0);
        transition: 0.2s all ease-in-out;
        padding-top: 24px;
        padding-bottom: 23px;
        @include media-breakpoint-up(md) {
          margin-top: 1.5rem;
        }

        &:hover:not(:disabled) {
          border-color: rgba($text-color, 1);
          color: $text-color;
          background: rgba($text-color, 0);
          box-shadow: none !important;
        }
        &:disabled:hover {
          box-shadow: none !important;
          cursor: not-allowed;
        }
      }
    }

    &__close {
      width: 28px;
      position: relative;
      cursor: pointer;
      display: block;
      @include media-breakpoint-up(md) {
        width: 34px;
      }

      .line {
        width: 100%;
        height: 2px;
        background: #1c0f0c;
        margin: 20px 0;
      }
    }
  }

  .slide-enter-active,
  .slide-leave-active {
    transition: all $shift-animation-duration $shift-animation-easing;
  }

  .slide-enter,
  .slide-leave-to {
    transform: translateX(100vw);
    @include media-breakpoint-up(md) {
      transform: translateX(548px);
    }
    opacity: 0;
  }

  &.fade-background-enter-active,
  &.fade-background-leave-active {
    transition: background $shift-animation-duration $shift-animation-easing;
  }
  &.fade-background-enter,
  &.fade-background-leave-to {
    background: rgba(0, 0, 0, 0);
  }
}
</style>
