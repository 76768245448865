<template>
  <component
    :is="tag"
    class="base-link"
    v-if="!linkdata.has_linkbutton && forceShowIfNull"
  >
    <slot></slot>
  </component>
  <component
    :is="tag"
    v-bind="$attrs"
    v-on="$listeners"
    class="base-link"
    v-else-if="linkdata.link_type == null"
  >
    <slot></slot>
  </component>
  <component
    :is="'a'"
    :href="'mailto:' + linkdata.target_email"
    class="base-link"
    v-else-if="linkdata.link_type === 'email'"
  >
    <slot></slot>
  </component>
  <component
    :is="'a'"
    :href="linkdata.target_download.url"
    class="base-link"
    target="_blank"
    v-else-if="linkdata.link_type === 'download'"
  >
    <slot></slot>
  </component>
  <component
    :is="'a'"
    :href="linkdata.target_external"
    class="base-link"
    target="_blank"
    v-else-if="linkdata.link_type === 'external'"
  >
    <slot></slot>
  </component>
  <component
    :is="'nuxt-link'"
    :event="prevent ? '' : 'click'"
    :to="{
      path: linkdata.target_internal_anchor.link,
      hash: linkdata.target_anchor
    }"
    class="base-link"
    v-else-if="linkdata.link_type === 'anchor'"
  >
    <slot></slot>
  </component>
  <component
    :is="'nuxt-link'"
    :event="prevent ? '' : 'click'"
    :to="linkdata.target_internal.link"
    class="base-link"
    v-else-if="
      linkdata.target_internal !== null && linkdata.link_type === 'internal'
    "
  >
    <span><slot></slot></span>
  </component>
</template>
<script>
export default {
  name: 'BaseLink',
  props: {
    tag: {
      type: String,
      default: () => 'link'
    },
    linkdata: {
      type: Object,
      default: () => {}
    },
    forceShowIfNull: {
      type: Boolean,
      default: false
    },
    prevent: Boolean
  },
  computed: {
    getTagForType() {
      return 'a'
    },
    rootPath() {
      return process.env.FRONTEND_STORAGE_URL
    }
  },
  methods: {
    pushAnchor(anchor) {
      window.scrollTo({
        top: document.getElementById(anchor).offsetTop,
        behavior: 'smooth'
      })
    }
  }
}
</script>
<style lang="scss"></style>
