import _ from 'lodash'

const defaultState = {
  checkout: { lineItems: [] },
  specialColor: {
    hex: '#123456',
    grad:
      'linear-gradient(145deg, #000000, #fdf8f1, #000000, #fdf8f1, #000000, #fdf8f1, #000000, #fdf8f1, #000000, #fdf8f1, #000000, #fdf8f1, #000000)'
  }
}

export const state = () => defaultState

export const actions = {
  async getProductByHandle({ commit }, handle) {
    try {
      const product = await this.$axios.get(
        `${process.env.FRONTEND_URL}/_shopify/`,
        {
          params: {
            action: 'getProductByHandle',
            handle
          }
        }
      )
      return product.data.body.productByHandle
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  },
  async getProductsById({ commit }, ids) {
    try {
      const products = await this.$axios.get(
        `${process.env.FRONTEND_URL}/_shopify/`,
        {
          params: {
            action: 'getProductsById',
            ids: ids.join(',')
          }
        }
      )
      return products.data.body.nodes
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  },
  async getCollectionById({ commit }, id) {
    try {
      // eslint-disable-next-line no-unused-vars
      const collection = await this.$axios.get(
        `${process.env.FRONTEND_URL}/_shopify/`,
        {
          params: {
            action: 'getCollectionById',
            id
          }
        }
      )

      _.remove(collection.data.body.node.products, function(cProduct) {
        return cProduct.publishedAt === null
      })

      return collection.data.body.node
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  },
  async loadCheckout({ commit }) {
    try {
      let checkout
      const checkoutID = this.$cookies.get('checkoutID')

      if (checkoutID) {
        checkout = await this.$shopify.checkout.fetch(checkoutID)

        if (checkout.completedAt) {
          checkout = await this.$shopify.checkout.create()
        }
      } else {
        checkout = await this.$shopify.checkout.create()
        // await this.$cookies.set('checkoutID', checkout.id)
        const d = new Date()
        d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000)
        const expires = 'expires=' + d.toUTCString()
        document.cookie =
          'checkoutID=' + checkout.id + ';' + expires + ';path=/'
      }
      commit('setCheckout', checkout)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  },

  async addLineItems({ state, commit }, lineItemsToAdd) {
    try {
      const checkout = await this.$shopify.checkout.addLineItems(
        state.checkout.id,
        lineItemsToAdd
      )
      commit('setCheckout', checkout)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  },

  async updateLineItems({ state, commit }, lineItemsToUpdate) {
    try {
      const checkout = await this.$shopify.checkout.updateLineItems(
        state.checkout.id,
        lineItemsToUpdate
      )
      commit('setCheckout', checkout)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  },

  async removeLineItems({ state, commit }, lineItemIdsToRemove) {
    try {
      const checkout = await this.$shopify.checkout.removeLineItems(
        state.checkout.id,
        lineItemIdsToRemove
      )
      commit('setCheckout', checkout)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }
}

export const mutations = {
  setCheckout(state, checkout) {
    state.checkout = checkout
  }
}

export const getters = {
  getProducts: (state) => {
    return state.products
  },
  getProductByID: (state) => (id) => {
    return state.products.find((product) => product.id === id)
  }
}
