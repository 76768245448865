<template>
  <base-section class="section error-section">
    <b-container>
      <b-row align-v="center">
        <b-col cols="12">
          <h1 v-if="error.statusCode === 404">Page not found</h1>
          <h1 v-else>Whoops, that page is gone.</h1>
          <p>
            Sorry about that. Just checkout our products and contents on our
            home page.
          </p>
          <nuxt-link to="/">Home page</nuxt-link>
        </b-col>
      </b-row>
    </b-container>
  </base-section>
</template>

<script>
export default {
  name: 'ErrorLayout',
  props: ['error']
}
</script>
<style lang="scss">
.error-section {
  padding: 20vw 0;
  min-height: 50vh;

  a {
    border: 1px solid $text-color;
    padding: 1rem 2rem 0.8rem;
    transition: 0.2s all cubic-bezier(0.455, 0.03, 0.515, 0.955);
    display: inline-block;

    @include media-breakpoint-up(sm) {
      margin-top: 1rem;
    }

    &:hover {
      text-decoration: none;
      background: $text-color;
      color: #fff;
    }
  }
}
</style>
