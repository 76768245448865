export const actions = {
  async nuxtServerInit({ dispatch, commit }, page) {
    const $axios = page.$axios
    const lang = page.app.i18n.locale
    await $axios
      .get(`${process.env.FRONTEND_URL}/_api/site`, { params: { lang } })
      .then((res) => {
        commit('site/setOptions', res.data)
      })
    await $axios
      .get(`${process.env.FRONTEND_URL}/_api/page`, { params: { lang } })
      .then((res) => {
        commit('site/setPages', res.data)
      })
  }
}
