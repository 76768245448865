var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"visibility"}},[_c('div',{staticClass:"main-nav-wrapper"},[_c('nav',{directives:[{name:"show",rawName:"v-show",value:(this.$store.state.ui.navOpen),expression:"this.$store.state.ui.navOpen"},{name:"win-resize",rawName:"v-win-resize",value:(_vm.onResize),expression:"onResize"}],staticClass:"main-nav"},[_c('div',{staticClass:"main-nav__line",on:{"click":function($event){return _vm.$store.commit('ui/toggleNav', false)}}},[_c('div',{staticClass:"line"})]),_vm._v(" "),_c('div',{staticClass:"main-nav__upper"},[_c('nuxt-link',{attrs:{"to":_vm.localePath('/')}},[_c('LilleSignet',{staticClass:"signet"})],1),_vm._v(" "),_c('div',{staticClass:"lang-switcher"},_vm._l((_vm.$i18n.locales),function(locale){return _c('a',{key:locale.code,staticClass:"md lang",class:{ 'lang-active': locale.code === _vm.$i18n.locale },attrs:{"href":_vm.switchLocalePath(locale.code)}},[_vm._v(_vm._s(locale.name))])}),0)],1),_vm._v(" "),_c('div',{staticClass:"main-nav__menu"},[_c('ul',{staticClass:"submenu lv1"},_vm._l((_vm.menu),function(item,i){return _c('li',{directives:[{name:"show",rawName:"v-show",value:(!item.hide),expression:"!item.hide"}],key:i,staticClass:"link",class:{
              inactive:
                !_vm.openSubmenu.includes(item.to) &&
                _vm.openSubmenu !== '' &&
                _vm.openSubmenu !== '/en/'
            }},[(
                (item.children === undefined &&
                  item.to !== undefined &&
                  !item.hide) ||
                  _vm.areChildrenHidden(item.children)
              )?_c('nuxt-link',{staticClass:"label h5",attrs:{"to":item.to}},[_c('span',[_vm._v(_vm._s(item.label))])]):(Array.isArray(item.children) && !item.hide)?[_c('div',{key:"label-lv1",staticClass:"label h5 line",class:{
                  inactive:
                    !_vm.openSubmenu.includes(item.to) &&
                    _vm.openSubmenu !== '' &&
                    _vm.openSubmenu !== '/en/'
                },on:{"click":function($event){return _vm.handleSubmenu(item.to)}}},[_c('span',[_vm._v(_vm._s(item.label))])]),_vm._v(" "),_c('transition',{attrs:{"name":"expanding","mode":"out-in"}},[(_vm.openSubmenu.includes(item.to) && !item.hide)?_c('ul',{key:"labels-lv2",staticClass:"submenu lv2"},_vm._l((item.children),function(child,j){return _c('li',{directives:[{name:"show",rawName:"v-show",value:(!child.hide),expression:"!child.hide"}],key:j,class:{
                      active: _vm.openSubmenu.includes(child.to),
                      line: j !== item.children.length - 1,
                      inactive: _vm.lvl2Inactive(child.to)
                    }},[(
                        (child.children === undefined &&
                          child.to !== undefined &&
                          !child.hide) ||
                          _vm.areChildrenHidden(child.children)
                      )?_c('nuxt-link',{staticClass:"label h5",attrs:{"to":child.to}},[_c('span',[_vm._v(_vm._s(child.label))])]):_c('div',{staticClass:"label h5",on:{"click":function($event){return _vm.handleSubmenu(child.to)}}},[_c('span',[_vm._v(_vm._s(child.label))])]),_vm._v(" "),_c('transition-group',{staticClass:"submenu lv3",class:{ mb: _vm.openSubmenu.includes(child.to) },attrs:{"tag":"ul","css":false},on:{"before-enter":_vm.staggerBeforeEnter,"enter":_vm.staggerEnter,"leave":_vm.staggerLeave}},_vm._l((child.children),function(link,k){return _c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.openSubmenu.includes(child.to)),expression:"openSubmenu.includes(child.to)"}],key:link.label + k,staticClass:"label sm lt",class:{ spacer: link.spacer },attrs:{"data-index":k}},[(link.spacer)?_c('div',[_vm._v(" ")]):_c('nuxt-link',{attrs:{"to":{ path: link.to }}},[_vm._v("\n                          "+_vm._s(link.label)+"\n                        ")])],1)}),0)],1)}),0):_vm._e()])]:_vm._e()],2)}),0)])]),_vm._v(" "),_c('div',{staticClass:"main-nav__lower mobile"},[_c('nuxt-link',{attrs:{"to":_vm.localePath('/')}},[_c('LilleSignet',{staticClass:"signet"})],1),_vm._v(" "),_c('div',{staticClass:"lang-switcher"},_vm._l((_vm.$i18n.locales),function(locale){return _c('a',{key:locale.code,staticClass:"md lang",class:{ 'lang-active': locale.code === _vm.$i18n.locale },attrs:{"href":_vm.switchLocalePath(locale.code)}},[_vm._v(_vm._s(locale.name))])}),0)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }