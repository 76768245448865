<template>
  <div
    v-if="media"
    class="media-element"
    :class="{
      'is-covered': !media.image_covered,
      'locked-aspect-ratio': lockedAspectRatio
    }"
  >
    <video-loop
      v-if="media.type == 'video'"
      :src="storageURL + media.video.url"
      :poster="storageURL + media.video_poster.url"
      :video-mobile="storageURL + media.video_mobile.url"
      :fallback="storageURL + media.video_mobile_poster.url"
      autoplay
    />
    <img
      class="lazyload"
      :alt="media.image.alt"
      v-if="showDesktopImage"
      data-sizes="auto"
      :data-srcset="getDesktopImageSizes"
      :data-src="storageURL + media.image.url"
      draggable="false"
    />
    <img
      class="lazyload"
      :alt="media.image.alt"
      v-if="showDesktopImage && lightbox"
      data-sizes="auto"
      :data-srcset="getDesktopImageSizes"
      :data-src="storageURL + media.image.url"
      draggable="false"
      v-preview
      :data-origin="storageURL + media.image.url"
    />
    <img
      class="lazyload"
      :alt="media.image.alt"
      v-if="showMobileImage"
      data-sizes="auto"
      :data-srcset="getMobileImageSizes"
      :data-src="storageURL + media.image_mobile.url"
      draggable="false"
    />
    <img v-if="media.url" :src="storageURL + media.url" draggable="false" />
    <div
      class="media-color"
      v-if="media.type == 'color'"
      :style="{ 'background-color': media.color }"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    media: {
      type: Object,
      default: () => {}
    },
    lockedAspectRatio: {
      type: Boolean,
      default: false
    },
    lightbox: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      viewWidth: 0,
      breakpoint: 768
    }
  },
  computed: {
    storageURL() {
      if (this.media.is_external_image) {
        return process.env.APP_URL
      } else {
        return ''
      }
    },
    showDesktopImage() {
      return (
        this.media.type === 'image' &&
        (this.viewWidth >= this.breakpoint || !this.media.seperate_mobile_asset)
      )
    },
    showMobileImage() {
      return (
        this.media.type === 'image' &&
        this.media.seperate_mobile_asset &&
        this.viewWidth < this.breakpoint
      )
    },
    getDesktopImageSizes() {
      const sizeStrData = []
      for (const sizeName in this.media.image.sizes) {
        const cSize = this.media.image.sizes[sizeName]
        sizeStrData.push(this.storageURL + cSize.url + ' ' + cSize.width + 'w')
      }
      return sizeStrData.join(', ')
    },
    getMobileImageSizes() {
      const sizeStrData = []
      for (const sizeName in this.media.image_mobile.sizes) {
        const cSize = this.media.image_mobile.sizes[sizeName]
        sizeStrData.push(this.storageURL + cSize.url + ' ' + cSize.width + 'w')
      }
      return sizeStrData.join(', ')
    }
  },
  mounted() {
    this.init()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  destroyed() {},
  methods: {
    init() {
      window.addEventListener('resize', this.onResize)
      this.onResize()
    },
    onResize(e) {
      this.viewWidth = window.innerWidth
    }
  }
}
</script>
<style lang="scss">
.media-element {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  video {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &.locked-aspect-ratio {
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &.is-covered {
    img,
    video {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
    video {
      height: 100%;
    }
  }
  .media-color {
    width: 100%;
    height: 100%;
  }
}
</style>
