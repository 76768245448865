import _ from 'lodash'

function filterProducts(store, app, activeFilter, dryRun = false) {
  const state = store.state.filter
  let filtered = state.filterData.products
  if (activeFilter.length === 0) {
    if (!dryRun) {
      store.commit('filter/updateFilterProducts', state.filterData.products)
    }
  } else {
    const groupedFilter = {}

    activeFilter.forEach((filterItem, index) => {
      if (!groupedFilter[filterItem.type]) {
        groupedFilter[filterItem.type] = []
      }
      groupedFilter[filterItem.type].push(filterItem.value)
    })

    for (const filterType in groupedFilter) {
      const filterValues = groupedFilter[filterType]
      filtered = _.chain(filtered)
        .map((product, index) => {
          if (
            product.filter[filterType].filter((value) =>
              filterValues.includes(value)
            ).length > 0
          ) {
            return product
          } else {
            return false
          }
        })
        .compact()
        .value()
    }
    if (!dryRun) {
      store.commit('filter/updateFilterProducts', filtered)
    }
  }
  if (!dryRun) {
    updateFilterOptions(store, app)
  }
  return filtered
}
function updateFilterOptions(store, app) {
  // filterNav
  const availibleFilter = []
  const products = store.state.filter.filterData.products

  store.state.filter.filterOptions.forEach((filterType, index) => {
    const cFilter = {
      title: app.i18n.t('shop.filter.' + filterType),
      type: filterType,
      options: []
    }

    const cOptions = _.chain(products)
      .map('filter')
      .map(filterType)
      .flatten()
      .uniq()
      .map((cValue, cIndex) => {
        const cID = `uid-${filterType}-${cValue.toLowerCase()}`
        const isActive =
          _.find(store.state.filter.activeFilter, ['id', cID]) !== undefined

        const filterObj = {
          value: cValue,
          active: isActive,
          count: 0,
          type: filterType,
          id: cID
        }

        const testFilter = _.chain(store.state.filter.activeFilter)
          .clone()
          .filter((item) => {
            return item.type !== filterType
          })
          .value()
        testFilter.push(filterObj)
        const possibleResults = filterProducts(store, app, testFilter, true)
        filterObj.count = possibleResults.length

        return filterObj
      })
      .value()

    if (cOptions.length > 2) {
      cFilter.options = cOptions
      availibleFilter.push(cFilter)
    }
  })

  store.commit('filter/updateFilterNav', availibleFilter)
}

export default ({ app, store }, inject) => {
  const state = store.state.filter
  inject('filter', {
    name: 'filter',
    set(filterList) {
      state.activeFilter = filterList
      filterProducts(store, app, state.activeFilter)
    },
    open(data) {
      // RESET ACTIVE FILTER IF OLD FILTER IS PRESENT
      if (!state.filterData || state.filterData.id !== data.id) {
        store.commit('filter/updateActiveFilter', [])
      }
      store.commit('filter/updateFilterData', data)
      filterProducts(store, app, state.activeFilter)
      store.commit('ui/openFilter')
    },
    add(filter) {
      let found = false
      const activeFilter = _.clone(state.activeFilter)
      for (const i in activeFilter) {
        if (activeFilter[i].id === filter.id) {
          found = true
          break
        }
      }
      if (!found) {
        activeFilter.push(filter)
      }
      store.commit('filter/updateActiveFilter', activeFilter)
      filterProducts(store, app, state.activeFilter)
    },
    remove(filter) {
      const activeFilter = _.clone(state.activeFilter)
      for (const i in activeFilter) {
        if (activeFilter[i].id === filter.id) {
          activeFilter.splice(i, 1)
          break
        }
      }
      store.commit('filter/updateActiveFilter', activeFilter)
      filterProducts(store, app, state.activeFilter)
    },
    toggle(filter) {
      let found = false
      const activeFilter = _.clone(state.activeFilter)

      for (const i in activeFilter) {
        if (activeFilter[i].id === filter.id) {
          activeFilter.splice(i, 1)
          found = true
          break
        }
      }
      if (!found) {
        activeFilter.push(filter)
      }
      store.commit('filter/updateActiveFilter', activeFilter)
      filterProducts(store, app, state.activeFilter)
    }
  })
}
