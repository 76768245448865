<template>
  <div
    v-show="this.$store.state.ui.shiftLeft || this.$store.state.ui.shiftRight"
    class="shift-backdrop"
    @click="$store.commit('ui/closeAll', false)"
  ></div>
</template>

<script>
export default {
  components: {}
}
</script>

<style lang="scss">
.shift-backdrop {
  position: absolute;
  z-index: 24;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  cursor: pointer;
}
</style>
