<template>
  <div class="video-wrapper">
    <video
      v-if="!isMobile"
      class="hero_video"
      ref="video"
      v-observe-visibility="visibilityChanged"
      :poster="poster"
      :src="src"
      :autoplay="autoplay"
      playsinline
      muted
      loop
    ></video>
    <video
      v-if="isMobile"
      class="mobile_video"
      ref="videoMobileRef"
      v-observe-visibility="visibilityMobileChanged"
      :poster="fallback"
      :src="videoMobile"
      :autoplay="autoplay"
      playsinline
      muted
      loop
    ></video>
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      default: () => null
    },
    poster: {
      type: String,
      default: () => null
    },
    fallback: {
      type: String,
      default: () => null
    },
    videoMobile: {
      type: String,
      default: () => null
    },
    autoplay: {
      type: Boolean
    }
  },
  data() {
    return {
      $video: null,
      $videoMobile: null,
      playing: false
    }
  },
  computed: {
    isMobile() {
      return this.$breakpoints.sSm
    }
  },
  mounted() {
    this.init()
  },
  destroyed() {
    this.$video = null
    this.$videoMobile = null
    this.playing = false
  },
  methods: {
    init() {
      if (this.$video) {
        this.$video = this.$refs.video
        this.$video.muted = true
      } else if (this.$videoMobile) {
        this.$videoMobile = this.$refs.videoMobileRef
        this.$videoMobile.muted = true
      }
    },
    visibilityChanged(inview) {
      if (inview && this.$video !== undefined) {
        this.play()
      } else {
        this.pause()
      }
    },
    visibilityMobileChanged(inview) {
      if (inview && this.$videoMobile !== undefined) {
        this.play()
      } else {
        this.pause()
      }
    },
    play() {
      if (
        (this.$video !== null && this.playing !== true) ||
        (this.$videoMobile !== null && this.playing !== true)
      ) {
        let promise = null
        if (this.$video) {
          promise = this.$video.play()
        } else if (this.$videoMobile) {
          promise = this.$videoMobile.play()
        }

        if (promise !== undefined || promise !== null) {
          promise
            .then((_) => {
              this.playing = true
            })
            .catch((_) => {
              this.playing = false
            })
        }
      }
    },
    pause() {
      if (
        (this.$video !== null && this.playing === true) ||
        (this.$videoMobile !== null && this.playing === true)
      ) {
        if (this.$video) {
          this.$video.pause()
        } else {
          this.$videoMobile.pause()
        }

        this.playing = false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.video-wrapper {
  display: block;
  width: 100%;
  height: 100%;
  .mobile_video {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    @include media-breakpoint-up(md) {
      display: none;
    }
  }
  .hero_video {
    display: none;
    @include media-breakpoint-up(md) {
      display: block;
    }
  }
}
</style>
