import _ from 'lodash'

function getColor(store, name) {
  if (name.includes('/')) {
    const splitColors = _.compact(name.split('/'))
    let gradient = 'linear-gradient(90deg'
    let foundAll = true
    splitColors.forEach((subColor, index) => {
      const cCol = getColor(store, subColor)
      if (!cCol) {
        foundAll = false
        return false
      } else {
        gradient += `, ${cCol.color} ${100 / splitColors.length}%`
      }
    })
    if (!foundAll) {
      return false
    }
    gradient += ')'
    return {
      name,
      color: gradient
    }
  }
  return _.chain(store.state.site.options.filter.colors)
    .map('matches')
    .flatten()
    .filter({ name })
    .first()
    .value()
}

function transformSpecialColor(store, hexColor) {
  if (hexColor === store.state.shop.specialColor.hex) {
    return store.state.shop.specialColor.grad
  } else {
    return hexColor
  }
}

function getColorGroup(store, name) {
  return _.find(store.state.site.options.filter.colors, ['name', name])
}

export default ({ app, store }, inject) => {
  inject('colors', {
    name: 'colors',
    getColorByName(name) {
      return getColor(store, name)
    },
    getColorTransformedByName(name) {
      const color = getColor(store, name)
      if (color) {
        return transformSpecialColor(store, getColor(store, name).color)
      } else {
        return false
      }
    },
    getColorGroupTransformedByName(name) {
      return transformSpecialColor(store, getColorGroup(store, name).color)
    },
    updateSpecialColors(hexColor) {
      return transformSpecialColor(store, hexColor)
    }
  })
}
