<template>
  <transition name="visibility">
    <nav
      v-if="$store.state.ui.filterOpen && $store.state.filter.filterData"
      class="filter-nav"
    >
      <div
        class="filter-nav__line"
        @click="$store.commit('ui/closeFilter', false)"
      >
        <div class="line"></div>
        <span>Filter</span>
      </div>

      <div class="accordion" role="tablist">
        <div
          v-if="$store.state.filter.filterData.nav.links"
          no-body
          class="accordion_item"
        >
          <div
            block
            v-b-toggle="'accordion-0'"
            variant="info"
            class="accordion_item__header"
          >
            <span class="label">{{
              $store.state.filter.filterData.nav.title
            }}</span>
            <span class="arrow-down"></span>
          </div>

          <b-collapse
            id="accordion-0"
            visible
            accordion="my-accordion"
            role="tabpanel"
            class="accordion_item__content"
          >
            <div class="accordion_item__content-wrapper">
              <filter-nav-urls
                :data="$store.state.filter.filterData.nav.links"
              />
            </div>
          </b-collapse>
        </div>
        <div
          no-body
          v-for="(item, i) in $store.state.filter.filterNav"
          :key="i"
          class="accordion_item"
        >
          <div
            block
            v-b-toggle="'accordion-' + (i + 1)"
            variant="info"
            class="accordion_item__header"
          >
            <span class="label">{{ item.title }}</span>
            <span class="arrow-down"></span>
          </div>

          <b-collapse
            :id="'accordion-' + (i + 1)"
            visible
            accordion="my-accordion"
            role="tabpanel"
            class="accordion_item__content"
          >
            <div class="accordion_item__content-wrapper">
              <filter-nav-sizes
                v-if="item.type === 'size'"
                :items="item.options"
              />
              <filter-nav-default v-else :items="item.options" />
            </div>
          </b-collapse>
        </div>
      </div>
    </nav>
  </transition>
</template>

<script>
import FilterNavDefault from '~/components/filter/FilterNavDefault'
import FilterNavSizes from '~/components/filter/FilterNavSizes'
import FilterNavUrls from '~/components/filter/FilterNavUrls'

export default {
  components: {
    FilterNavDefault,
    FilterNavSizes,
    FilterNavUrls
  },
  props: {},
  data() {
    return {
      openSubmenu: ''
    }
  },
  watch: {
    $route() {
      this.$store.commit('ui/closeFilter')
    }
  },
  methods: {
    testFilter(item) {},
    cleanupString(getStr) {
      if (getStr) {
        return getStr.toLowerCase().replace(/\W/g, '')
      }
      return 'unknown'
    }
  }
}
</script>

<style lang="scss">
.filter-nav {
  height: 100vh;
  width: 100vw;
  position: fixed;
  left: 0;
  z-index: 1;
  overflow-y: auto;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;

  @include media-breakpoint-up(md) {
    width: $filter-desktop-offset;
    flex-flow: column wrap-reverse;
  }

  &__line {
    width: 100%;
    min-height: 74px;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: flex-start;
    @include media-breakpoint-up(md) {
      display: none;
    }
    .line {
      display: inline-block;
      width: 28px;
      height: 2px;
      margin-left: 20px;
      background-color: $black;
      cursor: pointer;
    }
    span {
      display: inline-block;
      cursor: pointer;
      text-transform: uppercase;

      margin-left: 12px;
      font-weight: 500;
      letter-spacing: 0.89px;
      transform: translateY(2px);
    }
  }

  .accordion {
    width: 100vw;
    border-top: 1px solid rgba($black, 0.1);
    margin-top: 0;
    padding: 0 20px 0 20px;
    @include media-breakpoint-up(md) {
      margin-top: 74px;
      width: 320px;
      padding: 0 0;
    }

    &_item {
      &:first-child {
        &__header {
          border-top: 4px solid rgba($black, 0.1);
        }
      }

      &__header {
        outline: none;
        height: 60px;
        border-bottom: 1px solid rgba($black, 0.1);
        display: flex;
        align-items: center;
        justify-content: space-between;

        &.not-collapsed {
          .label {
            font-weight: normal;
          }

          border-bottom: 1px solid rgba($black, 0);
          .arrow-down {
            padding: 8px;
            border: solid rgba(#979797, 0.7);
            border-width: 1px 0 0 0;
            transform: translate(2px, 5px) rotate(0);
          }
        }
        .label {
          font-weight: normal;
          transition: color ease 0.4s;
        }
        .arrow-down {
          display: inline-block;
          margin-right: 8px;
          border: solid #979797;
          border-width: 0 1px 1px 0;
          padding: 5px;
          transform-origin: center center;
          transform: translate(0, -5px) rotate(45deg);

          @include media-breakpoint-up(md) {
            margin-right: 55px;
          }
        }
      }

      &__content {
        // background-color: yellowgreen;
        padding: 0 0;
        border-bottom: 1px solid rgba($black, 0);
        &.show {
          border-bottom: 1px solid rgba($black, 0.1);
        }
        &-wrapper {
          padding: 36px 30px 36px 0;
          @include media-breakpoint-up(md) {
            padding: 16px 55px 36px 0;
          }
        }
      }
    }
  }
}
</style>
