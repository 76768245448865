import Vue from 'vue'
import BaseLink from '~/components/base/BaseLink.global.vue'
import BaseSection from '~/components/base/BaseSection.global.vue'
import HoverImage from '~/components/media/HoverImage.global.vue'
import MediaElement from '~/components/media/MediaElement.global.vue'
import ResponsiveImage from '~/components/media/ResponsiveImage.global.vue'
import ShopifyImage from '~/components/media/ShopifyImage.global.vue'
import VideoLoop from '~/components/media/VideoLoop.global.vue'

Vue.component(BaseLink.name || 'BaseLink', BaseLink)

Vue.component(BaseSection.name || 'BaseSection', BaseSection)

Vue.component(HoverImage.name || 'HoverImage', HoverImage)

Vue.component(MediaElement.name || 'MediaElement', MediaElement)

Vue.component(ResponsiveImage.name || 'ResponsiveImage', ResponsiveImage)

Vue.component(ShopifyImage.name || 'ShopifyImage', ShopifyImage)

Vue.component(VideoLoop.name || 'VideoLoop', VideoLoop)
