<template>
  <div class="swiper-slide">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: 'SwiperSlide',
  ready() {
    this.update()
  },
  mounted() {
    this.update()
  },
  updated() {
    this.update()
  },
  attached() {
    this.update()
  },
  methods: {
    update() {
      if (this.$parent && this.$parent.swiper) {
        // this.$parent.update()
      }
    }
  }
}
</script>
