export default {
  bind(el, binding, vnode) {
    el.onWindowResize = function(event) {
      vnode.context[binding.expression](event)
    }
    window.addEventListener('resize', el.onWindowResize)
  },
  inserted(el) {
    el.onWindowResize()
  },
  unbind(el) {
    window.removeEventListener('resize', el.onWindowResize)
  }
}
