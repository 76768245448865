<template>
  <div
    class="zoom-on-hover"
    :class="{ zoomed }"
    @pointerover="touchzoom"
    @pointerout="touchzoom"
    @pointermove="move"
    @pointerenter="zoom"
    @pointerleave="unzoom"
  >
    <img
      class="normal lazyload"
      ref="normal"
      :data-src="defaultImage"
      :alt="altText"
    />
    <img
      class="zoom lazyload"
      ref="zoom"
      :data-src="zoomImage"
      :alt="altText"
    />
  </div>
</template>
<script>
export default {
  props: ['image', 'scale', 'disabled', 'altText'],
  data() {
    return {
      scaleFactor: 1,
      resizeCheckInterval: null,
      zoomed: false
    }
  },
  computed: {
    defaultImage() {
      const image = this.$shopifyImages.get(this.image.src, {
        size: 'x1024'
      })
      return image
    },
    zoomImage() {
      const bigImage = this.$shopifyImages.get(this.image.src, {
        size: 'x2048'
      })
      return bigImage
    }
  },
  mounted() {
    if (this.$props.scale) {
      this.scaleFactor = parseInt(this.$props.scale)
      this.$refs.zoom.style.transform = 'scale(' + this.scaleFactor + ')'
    }
    this.initEventLoaded()
    this.initEventResized()
  },
  updated() {
    this.initEventLoaded()
  },
  beforeDestroy() {
    this.resizeCheckInterval && clearInterval(this.resizeCheckInterval)
  },
  methods: {
    pageOffset(el) {
      const rect = el.getBoundingClientRect()
      const scrollLeft =
        window.pageXOffset || document.documentElement.scrollLeft
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop
      return {
        y: rect.top + scrollTop,
        x: rect.left + scrollLeft
      }
    },
    touchzoom(event) {
      if (this.disabled) return
      this.zoomed = event.type === 'pointerover'
      this.move(event)
    },
    zoom() {
      if (this.disabled) return
      this.zoomed = true
    },
    unzoom() {
      if (this.disabled) return
      this.zoomed = false
    },
    move(event) {
      const zoom = this.$refs.zoom
      const normal = this.$refs.normal
      if (this.disabled || !this.zoomed || !zoom || !normal) return
      const offset = this.pageOffset(this.$el)
      const relativeX = event.clientX - offset.x + window.pageXOffset
      const relativeY = event.clientY - offset.y + window.pageYOffset
      const normalFactorX = relativeX / normal.offsetWidth
      const normalFactorY = relativeY / normal.offsetHeight
      const x =
        normalFactorX *
        (zoom.offsetWidth * this.scaleFactor - normal.offsetWidth)
      const y =
        normalFactorY *
        (zoom.offsetHeight * this.scaleFactor - normal.offsetHeight)
      zoom.style.left = -x + 'px'
      zoom.style.top = -y + 'px'
    },
    initEventLoaded() {
      const promises = [this.$refs.zoom, this.$refs.normal].map(function(
        image
      ) {
        return new Promise(function(resolve, reject) {
          image.addEventListener('load', resolve)
          image.addEventListener('error', reject)
        })
      })
      const component = this
      Promise.all(promises).then(function() {
        component.$emit('loaded')
      })
    },
    initEventResized() {
      const normal = this.$refs.normal
      let previousWidth = normal.offsetWidth
      let previousHeight = normal.offsetHeight
      const component = this
      this.resizeCheckInterval = setInterval(function() {
        if (
          previousWidth !== normal.offsetWidth ||
          previousHeight !== normal.offsetHeight
        ) {
          previousWidth = normal.offsetWidth
          previousHeight = normal.offsetHeight
          component.$emit('resized', {
            width: normal.width,
            height: normal.height,
            fullWidth: normal.naturalWidth,
            fullHeight: normal.naturalHeight
          })
        }
      }, 1000)
    }
  }
}
</script>
<style scoped>
.zoom-on-hover {
  position: relative;
  overflow: hidden;
}
.zoom-on-hover .normal {
  width: 100%;
}
.zoom-on-hover .zoom {
  position: absolute;
  opacity: 0;
  transform-origin: top left;
}
.zoom-on-hover.zoomed .zoom {
  opacity: 1;
}
.zoom-on-hover.zoomed .normal {
  opacity: 0;
}
</style>
