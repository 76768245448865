<template>
  <div
    v-if="media"
    class="media-element"
    :class="{ is_covered: media.image_covered }"
  >
    <img
      class="lazyload"
      data-sizes="auto"
      :data-srcset="getImageSizes"
      :data-src="storageURL + media.url"
      draggable="false"
    />
  </div>
</template>

<script>
export default {
  props: {
    media: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      storageURL: process.env.FRONTEND_STORAGE_URL
    }
  },
  computed: {
    getImageSizes() {
      const sizeStrData = []
      for (const sizeName in this.media.sizes) {
        const cSize = this.media.sizes[sizeName]
        sizeStrData.push(this.storageURL + cSize.url + ' ' + cSize.width + 'w')
      }
      return sizeStrData.join(', ')
    }
  }
}
</script>
<style lang="scss">
.media-element {
  position: relative;
  width: 100%;
  height: 100%;
  &.is_covered {
    img,
    video {
      object-fit: cover;
    }
  }
  .media-color {
    width: 100%;
    height: 100%;
  }
}
</style>
