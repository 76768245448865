<template>
  <div class="filter-nav-default">
    <ul class="default-list">
      <li v-for="(item, i) in items" :key="i">
        <button
          class="default-item-btn"
          :class="[{ active: item.active }]"
          :disabled="!item.active && item.count === 0"
          @click="clickedItem(item, $event)"
        >
          <span
            v-if="item.type === 'color'"
            class="color-swatch"
            :style="{
              background: $colors.getColorGroupTransformedByName(item.value)
            }"
          ></span>
          {{ item.value }}
          <span v-if="item.count > 0">({{ item.count }})</span>
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    clickedItem(item, event) {
      this.$filter.toggle(item)
      event.target.classList.toggle('active')
    }
  }
}
</script>

<style lang="scss">
.filter-nav-default {
  ul {
    margin: 0 0;
    padding: 0 0;
    list-style: none;
    li {
      margin: 0 0;
      padding: 0 0;
      .default-item-btn {
        outline: none;
        border: none;
        background: transparent;
        padding: 8px 0;
        color: #8b8b8b;
        transition: color ease 0.4s, opacity ease 0.4s;

        &:disabled {
          opacity: 0.2;
        }

        &:not([disabled]) {
          &:hover,
          &.active {
            color: $black;
            font-weight: normal;
          }
        }

        .color-swatch {
          border: 1px solid #ccc;
          border-radius: 50%;
          display: inline-block;
          margin-right: 8px;
          transform: translateY(-1px);
          height: 20px;
          width: 20px;
          vertical-align: text-bottom;
        }
      }
    }
  }
}
</style>
